.pl-review-duplicate-card {
  margin-bottom: $layout-spacing-medium;

  & .pl-detailed-avatar {
    border: solid 1px $color-gray-15;
    border-radius: 2px;
  }
}

.pl-review-status-card {
  margin-bottom: $layout-spacing-medium;

  &__icon, &__text, &__button {
    align-items: center;
    display: flex;
  }

  &__text > div {
    display: block;
  }

  &__error-subtext {
    font-size: $font-xsmall;
  }
}
