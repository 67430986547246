$pl-chat-tail-size: 10px;
$pl-chat-right-margin-shift: 10px;
$pl-chat-preview-image-remove-size: 24px;
$pl-chat-overlay-background-color: rgba(0, 0, 0, .9);
$pl-chat-most-recent-button-height: 30px;
$pl-chat-fullscreen-image-height: 500px;
$pl-chat-fullscreen-image-width: 800px;

.chat-window {
  display: flex;
  flex-direction: column;
  position: static;

  .chat-spacer {
    flex-grow: 1;
  }
}

.pl-chat-name {
  font-size: $font-xsmall;
  font-weight: $font-semibold;
  margin-bottom: $layout-spacing-xsmall;
}
.pl-message-form {
  padding: 0 $layout-spacing-medium $layout-spacing-medium;
  .pl-addon-field__icon {
    align-self: flex-end;
    background: none;
    border: 0;
    color: $color-gray-50;
    &.disabled {
      color: $color-gray-10;
    }
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }
  .pl-addon-field__message-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 325px;
    overflow: auto;
    width: 100%;
  }
  .pl-addon-field__input {
    cursor: text;
    padding: $layout-spacing-small 0;
    width: 100%;
  }
  .pl-addon-field__input[placeholder]:empty::before {
    color: $color-gray-50;
    content: attr(placeholder);
  }

  .pl-addon-field__label {
    align-self: flex-end;
    color: $color-chat-message-send-bubble;
    cursor: pointer;
    font-size: $font-small;
    padding: $layout-spacing-small;
  }
}

.pl-chat-message-wrapper {
  clear: both;
  margin: 2px 0;
  padding: 0 $layout-spacing-medium;
  position: relative;
}
.pl-chat-message-wrapper--with-likes {
  margin-bottom: 10px;
}

.pl-chat-avatar {
  bottom: 0;
  float: left;
  left: 0;
  position: absolute;
  z-index: 10;
}

.pl-chat-message--date {
  color: $color-gray-50;
  text-align: center;
  text-transform: uppercase;
}

.pl-chat-message--user-info {
  padding-left: $layout-spacing-normal;

  &.pl-chat-message--user-info-sent {
    text-align: right;
  }
}

.pl-chat-message--name {
  color: $color-gray-50;
  font-size: $font-xxsmall;
}

.pl-chat-message--time {
  color: $color-gray-20;
  font-size: $font-xxsmall;
}

.pl-chat-message {
  background-color: $color-gray-10;
  border-radius: 10px;
  color: $color-content;
  display: flex;
  float: left;
  left: 16px;
  max-width: 70%;
  padding: $layout-spacing-small $layout-spacing-medium;
  position: relative;

  a {
    text-decoration: underline;
  }

  &::before {
    border-bottom-right-radius: 0 14px;
    border-left: 20px solid $color-gray-10;
    bottom: -2px;
    content: '';
    height: 15px;
    left: -10px;
    position: absolute;
    transform: translate(0, -2px);
    z-index: 2;
  }

  &::after {
    background: $bg-default;
    border-bottom-right-radius: $pl-chat-tail-size;
    bottom: -1px;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    transform: translate(-30px, 0);
    width: 30px;
    z-index: 3;
  }

  &.pl-chat-message--no-tail {
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }
}

.pl-chat-message--received {
  align-items: center;
  display: flex;
  float: left;
  position: relative;
  width: 100%;
}

.pl-chat-message--sent {
  flex-direction: row-reverse;
  float: right;

  .pl-chat-message {
    background: $color-chat-message-send-bubble;
    color: $color-content-inverse;
    float: right;
    left: 0;

    &::before {
      border-bottom-left-radius: 0 14px;
      border-left: 0;
      border-right: 20px solid $color-chat-message-send-bubble;
      content: '';
      left: auto;
      right: -10px;
      z-index: 0;
    }

    &::after {
      background: $bg-default;
      border-bottom-left-radius: $pl-chat-tail-size;
      content: '';
      left: auto;
      right: -60px;
      z-index: 1;
    }

    &.pl-chat-message--no-tail {
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
  }

  .pl-chat-message--time {
    float: right;
  }
}

// ## Image styling
%pl-chat-image {
  height: 164px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 400px;
  z-index: 0;

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &.pl-chat-image--no-tail {
    border-radius: $pl-chat-tail-size;
    margin: 0;
    max-width: calc(100% - #{$pl-chat-tail-size});
    width: 390px;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }
}

.pl-chat-preview-image {
  margin-top: $layout-spacing-small;
  position: relative;
  canvas {
    border-radius: $pl-chat-tail-size;
    display: block;
    height: auto;
    width: 200px;
  }
  .pl-chat-preview-image__remove--container {
    background-color: $color-highlight;
    border: 2px solid $bg-default;
    border-radius: 50%;
    box-shadow: 0 2px 2px 0 $color-content-lighter, 0 0 2px 0 $color-content-xlight;
    cursor: pointer;
    display: inline-block;
    height: $pl-chat-preview-image-remove-size;
    position: absolute;
    transform: translate(170px, 5px);
    width: $pl-chat-preview-image-remove-size;
  }
  .pl-chat-preview-image__remove--icon {
    color: $bg-default;
    display: inline-block;
    position: absolute;
    transform: translateY(-10px);
  }
}

%pl-chat-image-corner {
  content: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path fill="#fff" d="M10 10C10 4.781 5.078 0 0 0h10v10z"/></svg>');
  height: $pl-chat-tail-size;
  position: absolute;
  width: $pl-chat-tail-size;
  z-index: 1;
}

%pl-chat-image-tail {
  background: $bg-default;
  bottom: -1px;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: $pl-chat-tail-size;
  z-index: 1;
}

%pl-chat-image--with-text__image {
  border-radius: $pl-chat-tail-size $pl-chat-tail-size 0 0;
  margin: 0;
  max-width: calc(100% - #{$pl-chat-tail-size});
  width: 390px;
  &::before {
    content: none;
  }
  &::after {
    content: none;
  }
}

%pl-chat-image--with-text__message {
  clear: both;
  height: auto;
  &::before {
    content: none;
  }
  &.pl-chat-image--no-tail {
    border-radius: 0 0 $pl-chat-tail-size $pl-chat-tail-size;
    padding: $layout-spacing-small $layout-spacing-medium;
  }
}

.pl-chat-image--received {
  @extend %pl-chat-image;
  border-radius: 0 $pl-chat-tail-size $pl-chat-tail-size 0;
  float: left;
  left: 18px;
  margin-left: -$layout-spacing-medium;

  // Uses overlapping SVG to round top-left corner of masked image
  &::before {
    @extend %pl-chat-image-corner;
    left: $pl-chat-tail-size;
    transform: translate(-5.5px, 0) rotate(270deg);
  }

  // This adds the little chat tail to the bottom left
  &::after {
    @extend %pl-chat-image-tail;
    border-radius: 0 0 $pl-chat-tail-size;
    left: 0;
  }

  &.pl-chat-image--no-tail {
    left: 16px;
  }

  &.pl-chat-image--with-text__image {
    @extend %pl-chat-image--with-text__image;
    left: 16px;
  }

  &.pl-chat-image--with-text__message {
    @extend %pl-chat-image--with-text__message;
    background-color: $color-gray-10;
    border-radius: 0 0 $pl-chat-tail-size;
    color: $color-content;
    padding: $layout-spacing-small $layout-spacing-medium $layout-spacing-small 22px;
  }
}

.pl-chat-image--sent {
  @extend %pl-chat-image;
  border-radius: $pl-chat-tail-size 0 0 $pl-chat-tail-size;
  float: right;
  margin-right: -$pl-chat-right-margin-shift;

  // Uses overlapping SVG to round top-right corner of masked image
  &::before {
    @extend %pl-chat-image-corner;
    right: $pl-chat-tail-size;
    transform: translate(0, -5.5px);
  }

  // This adds the little chat tail to the bottom right
  &::after {
    @extend %pl-chat-image-tail;
    border-radius: 0 0 0 $pl-chat-tail-size;
    right: 0;
  }

  &.pl-chat-image--with-text__image {
    @extend %pl-chat-image--with-text__image;
    left: 0;
  }

  &.pl-chat-image--with-text__message {
    @extend %pl-chat-image--with-text__message;
    background-color: $color-chat-message-send-bubble;
    border-radius: 0 0 0 $pl-chat-tail-size;
    color: $color-content-inverse;
    padding: $layout-spacing-small 22px $layout-spacing-small $layout-spacing-medium;
  }
}

.pl-chat-image-wrapper {
  position: relative;
}

.chat-message-hover-menu-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}
.pl-chat-hover-menu {
  display: none;
  padding-left: 30px;
  position: absolute;
  right: -60px;
  top: -7px;

  .pl-chat-hover-menu--icon {
    &.pl-chat-popover-open,
    &:hover {
      cursor: pointer;
      svg {
        color: $color-highlight;
      }
    }

    &.vertical-ellipsis {
      svg {
        transform: rotate(90deg);
        width: 13px;
      }
    }
    svg {
      color: $color-gray-20;
      height: 13px;
      width: 11px;
    }
    .pl-chat-hover-menu--icon-liked-message {
      color: $color-alert;
      &:hover {
        color: $color-alert;
      }
    }
  }
}

.pl-chat-hover-menu--show {
  display: flex;
}

.pl-chat-liked-message {
  background: $bg-default;
  border: 1px solid $color-gray-05;
  border-radius: 100px;
  bottom: -10px;
  display: flex;
  padding: 0 $layout-spacing-xsmall;
  position: absolute;
  right: 10px;
  z-index: 2;
}
.pl-chat-liked-message--icon {
  height: 16px;
  line-height: 17px;
  &:hover {
    cursor: pointer;
    svg {
      color: $color-highlight;
    }
  }
  svg {
    color: $color-gray-20;
    width: 8px;
  }
  .pl-chat-liked-message--icon-liked {
    color: $color-alert;
    &:hover {
      color: $color-alert;
    }
  }
}
.pl-chat-liked-message--likes-count {
  color: $color-highlight;
  font-size: $font-xxsmall;
  line-height: 16px;
}

.pl-chat-hover-menu--send {
  left: -45px;
  padding-left: 0;
  padding-right: 15px;
}

.pl-chat-fullscreen__overlay {
  background-color: $pl-chat-overlay-background-color !important;
  color: $color-content-inverse;
  flex-direction: column;
}

.pl-chat-fullscreen__close {
  align-self: flex-end;
  color: $color-content-inverse;
  height: 28px;
  margin: $layout-spacing-medium;
}

.pl-chat-fullscreen__container {
  align-items: center;
  display: flex;
  height: 100%;
  margin-bottom: $layout-spacing-xlarge;
  min-height: $pl-chat-fullscreen-image-height;
  min-width: $pl-chat-fullscreen-image-width;
  width: 80%;
}

.pl-chat-fullscreen__carousel-button {
  background: $button-minimal-background;
  border: $button-minimal-border;
  color: $color-content-inverse;
  display: inline-block;

  &:hover:not(:disabled) {
    color: $color-gray-50;
  }

  &:disabled {
    color: $color-gray-50;
    opacity: .25;
  }
}

.pl-chat-fullscreen__content {
  max-height: 100%;
  width: 100%;
}

.pl-chat-fullscreen__content--wrapper {
  display: table;
  margin: auto;
}

.pl-chat-fullscreen__content--caption {
  caption-side: bottom;
  display: table-caption;
  font-size: $font-xsmall;
  height: 100px;
  margin: $layout-spacing-medium 0;
  overflow: auto;
}

.pl-chat-fullscreen__content--header {
  padding: $layout-spacing-normal 0;
}

.pl-chat-fullscreen__content--header-info {
  display: inline-block;
  font-size: $font-small;
  margin-left: $layout-spacing-medium;
  vertical-align: middle;
}

.pl-chat-fullscreen__content--time-sent {
  color: $color-gray-50;
  font-size: $font-xsmall;
}

.pl-chat-fullscreen__content--image {
  display: block;
  max-height: $pl-chat-fullscreen-image-height;
  max-width: 100%;
}

.pl-chat__unread-message-line {
  color: $color-alert;
  display: inline-block;
  font-weight: $font-bold;
  left: -$layout-spacing-normal;
  overflow: hidden;
  padding: $layout-spacing-normal 0;
  position: relative;
  vertical-align: bottom;
  width: calc(100% + #{$layout-spacing-large});
}

.pl-chat__unread-message-line::after {
  border-bottom: solid 2px $color-alert;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX($layout-spacing-normal);
  width: 100%;
}

.pl-chat-members-subsection:not(:last-child) {
  border-bottom: solid 1px $gray-light;
  padding-bottom: $layout-spacing-large;
}

.pl-chat-members-subsection:not(:first-child) {
  padding-top: $layout-spacing-large;
}

.pl-chat-members-subsection__header {
  font-size: $font-xsmall;
  font-weight: $font-semibold;
  padding-bottom: $layout-spacing-medium;
}

.pl-chat-members-subsection__subheader {
  color: $color-gray-50;
  font-size: $font-xsmall;
  font-weight: $font-regular;
}

.pl-chat-most-recent__button {
  background-color: $pl-chat-overlay-background-color;
  border: solid 1px #23282b;
  border-radius: 100px;
  bottom: 70px;
  color: white;
  cursor: pointer;
  font-size: $font-xsmall;
  height: $pl-chat-most-recent-button-height;
  left: 50%;
  line-height: $pl-chat-most-recent-button-height;
  position: absolute;
  text-align: center;
  transform: translate(-50%, 0);
  width: 175px;
  z-index: 99;

  span {
    padding-right: $layout-spacing-small;
  }
}
