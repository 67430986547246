// # Field Group
// Conjoin multiple text fields together.
%pl-field-group {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  z-index: 0;

  > * {
    flex: 1 1 auto;

    &:hover {
      z-index: $z-grouped-field--hover;
    }
    &:focus {
      z-index: $z-grouped-field--focus;
    }
  }

  > [class*='pl-button'] {
    @extend %button-synced-height;
  }

  > :not(:first-child) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-left: -1px;
  }

  > :not(:last-child) {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .pl-field-group__item--shrink {
    flex: 0 1 auto;
  }

  .pl-field-group__item--expand {
    flex: 10 1 auto;
  }

  .pl-field-group__text { // adds left and right spacing (for words, etc.)
    background: transparent;
    flex: 0 0 auto;
    padding: 0 .5em;
    white-space: nowrap;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  @each $field-group-width in (25, 30, 33.333, 40, 50, 66.666, 75, 100) {
    .pl-field-group__item--#{floor($field-group-width)} {
      flex: 1 1 #{ 1% * $field-group-width };
      width: #{ 1% * $field-group-width };
    }
  }
}

.pl-field-group {
  @extend %pl-field-group;
}
