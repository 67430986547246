$se-block-spacing: 16px;
$se-membership-card-border-radius: 10px;
$se-membership-card-box-shadow: 0 2px 4px 0 $color-gray-50;
$se-membership-card-padding: 12px 12px 24px 12px;

%se-membership-card {
  background-color: $bg-default;
  border-radius: $se-membership-card-border-radius;
  box-shadow: $se-membership-card-box-shadow;
  padding: $se-membership-card-padding;
  position: relative;
}


%se-app__logo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  flex-shrink: 0;
  font-size: 48px;
  height: 1em;
  line-height: 1;
  overflow: hidden;
  position: relative;
  width: 1em;
}

.se-membership-card {
  @extend %se-membership-card;

  &__info {
    display: flex;
    margin-top: 60px;
  }

  &__name {
    color: $color-gray-85;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 0;
  }

  &__number {
    @extend %se-text;
    font-weight: normal;
  }

  &__dates {
    @extend %se-text;
    font-weight: normal;
  }

  &__key {
    font-weight: 600;
  }
  &__text {
    margin-left: 12px;
  }
}

.se-membership-card-logo {
  display: flex;
  float: right;

  &__title {
    align-self: center;
    color: $color-gray-85;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    text-align: right;
  }

  &__image {
    @extend %se-app__logo;
    display: inline-block;
    flex-shrink: 0;
    height: 36px;
    margin-left: 8px;
    width: 36px;
  }
}

.se-membership-loading__container {
  min-height: 240px;
  position: relative;
}

%se-text {
  color: $color-gray-50;
}

.se-text {
  @extend %se-text;

  + .se-block {
    margin-top: $se-block-spacing;
  }
}
