%pl-modal,
.pl-modal {
  @include flex-box(column);
  background: #fff;
  border: 1px solid $gray-light;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
  margin: 0 auto;
  max-height: 75%;
  max-width: 75%;
  position: relative;
}

.pl-modal--extra-small {
  @extend %pl-modal;
  width: 300px;
}
.pl-modal--small {
  @extend %pl-modal;
  width: 400px;
}
.pl-modal--medium {
  @extend %pl-modal;
  width: 500px;
}
.pl-modal--medium-large {
  @extend %pl-modal;
  width: 600px;
}
.pl-modal--large {
  @extend %pl-modal;
  width: 750px;
}
.pl-modal--xxlarge {
  @extend %pl-modal;
  max-height: calc(100% - 48px); // might as well, it's xxlarge
  max-width: calc(100% - 48px); // screen would have to be 1400px+ wide for 1024px to be 75%
  width: 1024px;
}

// Columns don't work with auto height :(
.pl-modal--multi-column,
.pl-modal--multi-column-add {
  @extend %pl-modal;
  height: 100%;
}

.pl-modal__header {
  @extend .pl-pane--shrink;
  border-bottom: 1px solid $gray-light;
  padding: ($layout-spacing-large - 4) $layout-spacing-large;
  position: relative;
  h1 {
    margin: 0;
  }
  .pl-modal__close {
    margin: 0;
    position: absolute;

    & + h1 {
      margin: 0;
      padding-right: 40px;
    }
  }
}

$pl-modal-close-size: 20px;

.pl-modal__close {
  @extend %pl-close;
  display: block;
  font-size: $pl-modal-close-size;
  height: $pl-modal-close-size;
  position: absolute;
  right: $layout-spacing-large;
  top: $layout-spacing-large;
  width: $pl-modal-close-size;
  z-index: 1;

  &:disabled {
    opacity: .3;
  }

  & + *::before {
    content: '';
    float: right;
    height: $pl-modal-close-size + $layout-spacing-medium;
    width: $pl-modal-close-size + $layout-spacing-medium;
  }
}

.pl-modal__content,
%pl-modal__content {
  @extend .pl-pane;
  flex-basis: auto; // fixes shrinking problem in Safari
  height: 100%; // allows full height content to size correctly
}

.pl-modal__content--loading {
  @extend %pl-modal__content;
  min-height: 100px; // give the spinners some space
}

.pl-modal__content--collapse {
  @extend %pl-modal__content;
  padding: 0;
}

.pl-modal__content--border {
  @extend %pl-modal__content;
  border-bottom: 1px solid $gray-light;
}

.pl-modal__content--iframe {
  @extend %pl-modal__content;
  height: 100vh;
  padding: 0;
}

.pl-modal__iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.pl-modal__section {
  margin: 0 (-$layout-spacing-medium);
  padding: $layout-spacing-medium;
  @media (min-width: $tablet-width) {
    margin: 0 (-$layout-spacing-large);
    padding: $layout-spacing-large;
  }
}

.pl-modal__section + .pl-modal__section {
  border-top: 1px solid $gray-light;
}

.pl-modal__footer {
  @extend .pl-pane--shrink;
  border-top: 1px solid $gray-light;
  line-height: 30px; // $sn-input-lg // to match button height
  padding: $layout-spacing-medium $layout-spacing-large;
  text-align: right;
  z-index: 1; // fixes border issue (probably rounding related)

  [class*='pl-button'] {
    border-color: transparent;
  }

  // .sn-notification::before {
  //   line-height: 30px; // $sn-input-lg
  // }
}

.pl-modal--condense {
  @extend %pl-modal;

  .pl-modal__header,
  .pl-modal__content:not(.pl-modal__content--collapse),
  .pl-modal__footer {
    padding: $layout-spacing-medium;
  }

  .pl-modal__footer {
    border-width: 0;
    padding-top: 0;
  }

  .pl-modal__close {
    right: $layout-spacing-medium;
    top: $layout-spacing-medium;
  }
}

// THESE CANNOT BE NESTED

// IE 10/11 cannot figure out the height unless specifically set
// Apps using this should have JS set the height based on content.
// This is just a fallback to prent stuff from totally breaking.
.ie10 %pl-modal,
.ie11 %pl-modal {
  height: 75%;
}

.pl-modal__component {
  display: block;
  min-height: 360px;
}
