// # Page header
// Includes the page title, and optionally a description or tabs for navigation

.pl-page-header {
  border-bottom: solid 1px $color-content-xxlight;
  padding-left: .8571em; // 12px / 14px
  padding-right: .8571em; // 12px / 14px
  padding-top: 1.4286em; // 20px / 14px

  @include media-small {
    padding-left: 1.7143em; // 24px / 14px
    padding-right: 1.7143em; // 24px / 14px
  }
}

.pl-page-header__title {
  @include font-large;
  display: inline-block;
  font-family: $font-heading;
  font-weight: $font-regular;
  margin-bottom: .25em;

  &:last-child {
    margin-bottom: 1.1429rem; // 16px / 14px
  }
}

.pl-page-header__description {
  color: $color-content-light;
  display: inline-block;
  margin-left: .8571em; // 12px / 14px

  &:last-child {
    margin-bottom: 1.1429rem; // 16px / 14px
  }
}

.pl-page-header__heading {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  [class^='pl-heading'] {
    order: 2;
  }
}

.pl-page-header__header-controls {
  order: 1;
  padding: .25rem 0 1.375rem;
}

@media (min-width: $media-small) {
  .pl-page-header__heading {
    flex-direction: row;
    [class^='pl-heading'] {
      order: 1;
    }
  }
  .pl-page-header__header-controls {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: 0;
    order: 2;
    padding: 0;
  }
}
