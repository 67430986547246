// # Buttons
// Button elements, submit inputs, and button-styled links

// # Default
%button {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  color: $button-color;
  display: inline-block;
  font-family: $button-font-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  line-height: $button-line-height;
  padding: $button-padding-vertical $button-padding-horizontal;
  text-align: center;
  text-decoration: none !important; // sometimes this is used to override links that can have hover states with underline
  text-transform: $button-text-transform;
  vertical-align: middle;

  // TODO: don't apply :hover styles on touch devices
  &:hover:not(:disabled):not(.pl-is-disabled),
  &:active:not(:disabled):not(.pl-is-disabled),
  &.is-active:not(:disabled):not(.pl-is-disabled) {
    background: $button-hover-bg;
    color: $button-color;
  }
  &:active:not(:disabled):not(.pl-is-disabled),
  &.is-active:not(:disabled):not(.pl-is-disabled) {
    background: $button-active-bg;
  }
  &:focus:not(:disabled):not(.pl-is-disabled) {
    background: $button-focus-bg;
    outline: none;
  }
  &:disabled,
  &.pl-is-disabled {
    background: $button-disabled-bg;
    color: $button-disabled-color;
    cursor: default;
  }
  img {
    margin: 0; // override default platform img margin-bottom
    vertical-align: bottom; // baseline causes odd alignment with text
  }
}

%button-high-disabled {
  &:disabled,
  &.pl-is-disabled {
    background: $button-disabled-bg-high;
    box-shadow: none;
    color: $button-disabled-color-high;
  }
}

%button-synced-height {
  padding-bottom: $button-padding-vertical-synced;
  padding-top: $button-padding-vertical-synced;
}

%pl-button {
  @extend %button;
}

%pl-button--destroy {
  @extend %button;
  @extend %button-high-disabled;
  background: $button-destroy-bg;
  border-color: transparent;
  box-shadow: $button-destroy-box-shadow;
  color: $button-destroy-color;

  // TODO: don't apply :hover styles on touch devices
  &:hover:not(:disabled):not(.pl-is-disabled),
  &:active:not(:disabled):not(.pl-is-disabled),
  &.is-active:not(:disabled):not(.pl-is-disabled) {
    background: $button-destroy-hover-bg;
    color: $button-destroy-color;
  }
  &:active:not(:disabled):not(.pl-is-disabled),
  &.is-active:not(:disabled):not(.pl-is-disabled) {
    background: $button-destroy-active-bg;
    box-shadow: $button-destroy-active-box-shadow;
  }
  &:focus:not(:disabled):not(.pl-is-disabled) {
    background: $button-destroy-focus-bg;
    box-shadow: $button-destroy-focus-box-shadow;
  }
}

%pl-button--highlight {
  @extend %button;
  @extend %button-high-disabled;
  background: $button-highlight-bg;
  border-color: transparent;
  box-shadow: $button-highlight-box-shadow;
  color: $button-highlight-color;

  // TODO: don't apply :hover styles on touch devices
  &:hover:not(:disabled):not(.pl-is-disabled),
  &:active:not(:disabled):not(.pl-is-disabled),
  &.is-active:not(:disabled):not(.pl-is-disabled) {
    background: $button-highlight-hover-bg;
    color: $button-highlight-color;
  }
  &:active:not(:disabled):not(.pl-is-disabled),
  &.is-active:not(:disabled):not(.pl-is-disabled) {
    background: $button-highlight-active-bg;
    box-shadow: $button-highlight-active-box-shadow;
  }
  &:focus:not(:disabled):not(.pl-is-disabled) {
    background: $button-highlight-focus-bg;
    box-shadow: $button-highlight-focus-box-shadow;
  }
}

%pl-button--large {
  @extend %button;
  font-size: $button-large-font-size;
  line-height: $button-large-line-height;
  padding: $button-large-padding;
}

%pl-button--full {
  @extend %button;
  text-align: center;
  width: 100%;
}

// Mimic CL low buttons
%pl-button--minimal {
  background: $button-minimal-background;
  border: $button-minimal-border;
  color: $button-minimal-color;
  display: inline-block;
  font-family: $button-minimal-font-family;
  font-weight: $button-minimal-font-weight;
  line-height: $button-line-height;
  margin: 0 0 - $button-minimal-padding;
  padding: $button-minimal-padding;
  text-decoration: none;

  // TODO: don't apply :hover styles on touch devices
  &:focus {
    outline: dotted 1px;
  }
  &:hover:not(:disabled):not(.pl-is-disabled) {
    color: $gray;
  }
  &.pl-is-active:not(:disabled):not(.pl-is-disabled),
  &:active:not(:disabled):not(.pl-is-disabled),
  &.is-active:not(:disabled):not(.pl-is-disabled) {
    color: $color-content;
  }
  &:disabled {
    color: $gray;
    cursor: default;
    opacity: .25;
  }
  &:not(:first-child) {
    margin-left: $button-minimal-spacing;
  }
}
