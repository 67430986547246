%pl-dropdown-menu {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px #d4d9dc, 0 2px 5px 1px rgba(0, 0, 0, .2);
  display: block;
  margin: 2px 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 500;
}

%pl-dropdown-menu__item {
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  text-align: left;
  user-select: none;
  width: 100%;

  &:hover,
  &:focus {
    background: $gray-xlight;
    outline: none;
  }
}

.pl-dropdown-menu {
  @extend %pl-dropdown-menu;
}

.pl-dropdown-menu--no-icons {
  @extend %pl-dropdown-menu;

  [class*='pl-icon'] {
    display: none;
  }
}

.pl-dropdown-menu__header {
  background: $gray-xlight;
  border-bottom: 1px solid $gray-light;
  border-top: 1px solid $gray-light;
  cursor: default;
  display: block;
  padding: .2857rem .8571rem;
}

.pl-dropdown-menu__item {
  @extend %pl-dropdown-menu__item;
  padding: .2857rem .8571rem;
}

.pl-dropdown-menu__item--empty-result {
  @extend %pl-dropdown-menu__item;
  color: $gray-light;
  padding: .2857rem .8571rem;
  text-align: center;
}

.pl-dropdown-menu__item--indented {
  @extend %pl-dropdown-menu__item;
  padding: .2857rem .8571rem;
  padding-left: .8571rem * 2.5;
}

.pl-dropdown-menu__item--no-focus {
  @extend %pl-dropdown-menu__item;
  &:focus {
    background: inherit;
  }
  &:hover  {
    background: $gray-xlight;
  }
}
