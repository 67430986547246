$font-comment-size: 12px;
$welcome-container-size: 120px;
$follow-item-icon-size: 28px;
$follow-question-icon-size: 10px;
$avatar-icon-size: 36px;
.pl-feed__welcome-container {
  background: $bg-default;
  margin: $layout-spacing-medium;
  padding: $layout-spacing-medium;
}

.pl-feed__welcome-close {
  position: relative;
}

.pl-feed__welcome-action {
  color: $color-feed-panel-dark-light;
  position: absolute;
  right: 0;
  top: 6px;
  z-index: 4;
}

.pl-feed__welcome-image {
  padding-top: 2em;
}

.pl-feed__welcome-icon {
  background-image: url('#{$shared-image-path}/hero.svg');
  background-size: cover;
  height: $welcome-container-size;
  width: $welcome-container-size;
}

.pl-feed__welcome-info {
  padding: $layout-spacing-medium $layout-spacing-large * 4;
  text-align: center;
}

.pl-feed__welcome-title {
  @extend %content-spacing;
  color: $color-feed-button-blue;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.18;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  text-align: center;
}

.pl-feed__welcome-text {
  @extend %content-spacing;
  color: $squadlocker-title-color;
  font-size: $font-small;
  line-height: 1.25;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  text-align: center;
}

.pl-feed {
  border: 0;
}

.pl-feed__inner {
  background: $bg-default;
  .pl-feed-card {
    padding-bottom: 1em;
  }
}

.pl-feed__personalize {
  background-image: linear-gradient(to bottom, $color-feed-button-blue, $color-feed-button-blue-light);
  border: solid 1px $color-feed-button-blue;
  border-radius: $input-border-radius * 2;
  display: block;
  width: 100%;
}

.pl-follow-item {
  &__icon {
    position: absolute;
    right: 0;
    top: .1em;
  }
  &__label {
    color: $color-gray-85;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0;
    text-align: left;
  }
  &__title {
    @extend %heading;
    color: $gray-xdark;
    font-size: $font-xsmall;
    line-height: 1.43;
    text-align: left;
  }
  &__desc {
    color: $color-gray-50;
    font-size: $layout-spacing-medium;
    line-height: 1.33;
    text-align: left;
  }
}

.pl-follow-item--horizontal {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  .pl-follow-item-avatar {
    flex: 0 0 auto;
    margin-bottom: 0;
    padding: $layout-spacing-medium;
    padding-left: 0;
    &__image {
      background: $bg-default url('#{$shared-image-path}/avatar.svg') no-repeat 50%;
      background-size: cover;
      border-radius: $layout-spacing-medium + 2;
      box-shadow: 0 0 0 2px $bg-default;
      height: $follow-item-icon-size;
      width: $follow-item-icon-size;
    }
  }
  .pl-follow-item {
    &__group {
      flex: 1 1 50%;
      padding: $layout-spacing-medium;
    }
    &__actions {
      padding-left: $layout-spacing-medium;
      padding-top: $layout-spacing-medium;
    }
  }
}

.pl-follow__panel {
  background-color: $bg-default;
  border-radius: $input-border-radius;
  box-shadow: 0 1px 2px 0 $color-feed-panel-dark-light;
  margin-top: 1em;
  padding: $layout-spacing-medium;
  .pl-follow-item--icon {
    padding-right: $layout-spacing-normal;
    position: relative;
    .pl-media__image {
      margin: 0;
    }
  }
}

.pl-follow-item--border {
  border-top: 1px solid $color-gray-10;
  text-align: center;
  .refresh-button {
    color: $color-feed-button-blue;
    font-size: $font-xsmall;
    font-weight: 400;
    line-height: 1.43;
    padding-top: $layout-spacing-small;
    text-align: left;
  }
}

.pl-follow__panel-question--icon {
  fill: $color-gray-20;
  height: $follow-question-icon-size;
  margin-right: 0;
  width: $follow-question-icon-size;
}

.pl-follow-item__actions--default {
  .pl-follow-item__desc,
  .follow-icon {
    color: $color-feed-button-blue;
  }
}

.pl-follow-item__actions--active {
  .pl-follow-item__desc,
  .follow-icon {
    color: $color-feed-button-green;
  }
}

.pl-feed__inner--right {
  padding-left: 2em;
}

@media (min-width: $tablet-width) {
  .pl-feed {
    margin: $layout-spacing-large 0;
  }
  .pl-feed__container {
    padding: 0 $layout-spacing-large;
  }
  .pl-feed__inner {
    background: $gray-xxlight;
    padding: $layout-spacing-large $layout-spacing-medium;
  }
}
