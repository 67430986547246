// iOS doesn't respect iframe sizing so we set the same size and scroll on the wrapper
.pl-iframe__wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (max-width: $media-small) {
    height: calc(100% - #{$ngin-bar-height});
    position: fixed;
  }
}

// This is a hack to get iOS iframes to size correctly: https://github.com/ampproject/amphtml/issues/11133
.pl-iframe {
  height: 0;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 0;
}

.pl-iframe__spinner {
  align-items: center;
  background: #fff;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  spinner {
    width: 100%;
  }
}

.pl-iframe__wrapper--flex {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

// This class should be applied to the html tag of the iframed content to make internal scrolling work correcly on iOS
.pl-iframe__html {
  height: 100% !important;
  overflow: hidden !important;

  > body {
    -webkit-overflow-scrolling: touch !important;
    height: 100% !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}
