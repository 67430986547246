.pl-list-grid {
  font-size: $font-small;
  user-select: none;
}

.pl-list-grid__header {
  color: $color-content-light;
  font-family: $font-heading;
  font-size: $font-large;
  font-weight: $font-regular;
  margin-bottom: $layout-spacing-medium;
  text-align: left;
}

.pl-list-grid__items {
  line-height: 2;
  list-style: none;
  min-height: calc(#{$font-small * 2} + #{$layout-spacing-medium * 2});
  position: relative;

  &:not(:first-child) {
    margin-top: $layout-spacing-medium; // Limits the spacing to when it's preceded by something (e.g. header)
  }

  #{'.dndPlaceholder'} {
    background-color: $gray-xlight;
    display: block;
    min-height: calc(#{$font-small * 2} + #{$layout-spacing-medium * 2});
  }
}

.pl-list-grid__header-item {
  background-color: $gray-xlight;
  border: 1px solid $gray-light;
  color: $color-content;
  font-weight: bold;
  padding: $layout-spacing-xsmall $layout-spacing-normal;
  text-align: left;
}

%pl-list-grid__item {
  align-items: center;
  background-color: #fff;
  border: 1px solid $gray-light;
  border-radius: 2px;
  color: $color-content-light;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: -1px; // collapse borders
  padding: $layout-spacing-medium $layout-spacing-normal;
  position: relative;

  @media(max-width: $media-small) {
    margin: -1px -1px 0;
  }

  .pl-button--minimal {
    padding: 0;
  }

  &#{'.dndDraggingSource'} {
    display: none;
  }
}

.pl-list-grid__item {
  @extend %pl-list-grid__item;
}

.pl-list-grid__item--no-border {
  @extend %pl-list-grid__item;
  border: 0;
  border-radius: 0;
}

.pl-list-grid__item--collapse {
  @extend %pl-list-grid__item;
  padding: 0;

  &:not(:first-child) {
    margin-top: $layout-spacing-medium;
  }
}

.pl-list-grid__item--large {
  @extend %pl-list-grid__item;

  .pl-list-grid__item-name {
    font-size: $font-medium;
    font-weight: $font-semibold;
    line-height: $font-medium * 1.4;
  }

  .pl-list-grid__item-details {
    font-size: $font-xsmall;
    line-height: $font-small;
  }
}

.pl-list-grid__item--draggable {
  cursor: move;
}

.pl-list-grid__item--draggable-y {
  cursor: ns-resize;
}

.pl-list-grid__item--draggable,
.pl-list-grid__item--draggable-y {
  @extend %pl-list-grid__item;

  &:hover {
    border-color: $gray;
    z-index: $z-grouped-field--hover; // put bottom border above following item
  }

  &#{'.dndDragging'} {
    border-color: $gray;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
  }

  &.pl-is-selected,
  &.pl-is-selected:hover {
    background-color: $color-highlight;
    border-color: $color-highlight;
    color: #fff;
  }

  &.pl-is-disabled {
    background-color: #fff;
    border-color: $gray-light;
    color: $gray-light;
  }
}

.pl-list-grid__item-avatar {
  height: $font-xxxlarge;
  width: $font-xxxlarge;

  > .pl-avatar {
    font-size: $font-xxxlarge;
  }
}

.pl-list-grid__item-icon {
  @extend %pl-icon;

  flex: 0 0 auto;
  height: 1.3195rem; // 18px
  width: 1.3195rem; // 18px
}

.pl-list-grid__item-actions {
  flex: 0 1 auto;
  padding: 0 $layout-spacing-small 0 $layout-spacing-xsmall;
  text-align: right;
  white-space: nowrap;

  .pl-link + .pl-link {
    margin-left: $layout-spacing-small;
  }

  > .pl-button--minimal {
    margin-left: $layout-spacing-large;
  }
}

.pl-list-grid__item-inner {
  flex: 1 1 100%;
  overflow: hidden;

  &:not(:first-child) {
    padding-left: $layout-spacing-medium;
  }

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pl-link + .pl-link {
    margin-left: $layout-spacing-small;
  }
}

.pl-list-grid__item-name {
  color: $color-content;
  font-size: $font-small;
  line-height: $font-large;
}

.pl-list-grid__item-details {
  color: $color-content-lighter;
  font-size: $font-xxsmall;
  line-height: $font-xsmall;
}

.pl-list-grid__item--selectable {
  @extend %pl-list-grid__item;

  &:hover {
    border-color: $gray;
    z-index: $z-grouped-field--hover; // put bottom border above following item
  }

  &.pl-is-selected,
  &.pl-is-selected:hover {
    background-color: $color-highlight-light;
    border-color: $color-highlight;
  }

  &.pl-is-disabled {
    background-color: #fff;
    border-color: $gray-light;
    color: $gray-light;
  }
}

.pl-list-grid__selected-icon {
  fill: $color-highlight;
  position: absolute;
  right: 16px;
  top: calc(50% - 8px);
}
