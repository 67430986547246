// # Tables
// Styling for table elements


// ## Constants
$input-size: 1rem; //14px
$table-horizontal-spacing: 2em;


// ## Data Table
.pl-data-table__container {
  overflow: auto;
}

%pl-data-table {
  width: 100%;

  th {
    background-color: $gray-light;
    color: $color-content-light;
    font-size: $font-xxsmall;
    text-align: left;
    text-transform: uppercase;

    @each $align in [left, center, right] {
      &[align='#{$align}'],
      &.pl-data-table__header--#{$align} {
        text-align: $align;
      }
    }

    // Sortable column classes
    &.pl-sortable-column {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        display: inline;
        display: -moz-inline-grid; // sass-lint:disable-line no-duplicate-properties
        max-width: calc(100% - 1.25em);
      }

      svg.pl-sort-icon {
        fill: $gray-medium;
        height: 1em;
        margin-left: .5em;
        vertical-align: -.1em;
        width: .8em;
      }

      &.pl-sortable-active {
        background-color: $gray-medium;

        svg.pl-sort-icon {
          fill: $gray-dark;
        }
      }
    }
  }
  tr:nth-child(even) {
    background-color: $color-content-xxxlight;
  }
  tr {
    font-size: $font-xsmall;
    &.selected {
      background: $color-highlight;
      color: #fff;
      .ta-messages-opted-in {
        color: #fff;
      }
      .ta-messages-opted-out {
        color: #fff;
      }
      .ta-messages-pending {
        color: #fff;
      }
      & input {
        color: #000;
      }
    }
  }
}

.pl-data-table {
  @extend %pl-data-table;

  th,
  td {
    padding: .2857rem .4286rem; // 4px/14px, 6px/14px

    &:first-child {
      padding-left: .8571rem; // 12px/14px
    }
    &:last-child {
      padding-right: .8571rem; // 12px/14px
    }
  }

  td {
    text-align: left;

    // Override the default cell padding for indented cells.
    // e.g. <td class="indent-1"> for a 1-level indented item within a tree-style list
    @for $i from 1 through 6 {
      &.indent-#{$i} {
        padding-left: 6px + $layout-spacing-medium * $i;
      }
    }
  }

  &.sn-sticky-column > * > * > *:last-child {
    padding-right: .4286rem; // override last-child padding from tables
  }

  &.sn-sticky-header [sticky-column] {
    border-right: 1px solid $color-content-xlight; // darker than header background-color
  }
  [sticky-column] {
    border-right: 1px solid $color-content-xxlight;
  }

  &.fixed {
    table-layout: fixed;
  }
  .pl-checkbox-cell {
    height: 30px;
    padding-left: $layout-spacing-medium !important;
    padding-right: $layout-spacing-medium / 2 !important;
    width: 36px;
    .pl-option__label::before {
      top: calc(.8665em - #{$input-size / 2});
    }
  }
}


// ## Plain table
// Has no borders and a minimal amount of styling
.pl-plain-table {
  th,
  td {
    padding: .25em .5em;
    vertical-align: top;
  }
  th {
    text-align: left;
  }
  tfoot {
    border-top: solid 1px $gray-xlight;
    td {
      padding-top: .5em;
    }
  }
}

.pl-plain-table__total {
  font-weight: $font-semibold;
  position: relative;
  text-align: right;
}
