// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Colors
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$se-gray:           #c1cace;
$se-blue:           #008cc3;
$se-white:          #fff;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Settings / Defaults
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Primary
$se-primary-base:   $se-blue;
$se-primary:        darken($se-primary-base, 10.25);

$se-primary-500:    $se-primary;
$se-primary-900:    darken($se-primary-base, 20.2);

// Grays
$se-gray-700:       darken(desaturate(adjust-hue($se-gray, 5), 1.76), 32.94);
$se-gray-900:       darken(desaturate(adjust-hue($se-gray, 4), 1.46), 62.94);

button {
  &.se-container-card__action,
  &.se-menu-reference,
  &.myse-mobile-callout__close,
  &.pl-card__close,
  &.pl-card__close-reversed {
    &:focus {
      border-color: $se-primary-500;
      border-radius: 50%;
      border-style: solid;
      border-width: 2px;
      outline: 2px solid white;
    }
}}

button{
  &.myse-mobile-callout__close
  &.pl-card__close,
  &.pl-card__close-reversed {
    &:focus {
      height: 26px;
      width: 26px;
      line-height: 26px;
      padding: 0px;
      top: 4px;
      right: 4px;
    }
  }
}

button.pl-card__close, button.pl-card__close-reversed {
  &:focus {
    z-index: 4;
  }
}

button.se-container-card__action {
  color: $se-gray-700;

  &:hover {
    color: $se-gray-900;
  }
}

// Forza font overrides 

// Left nav
.se-left-nav-context__header {
  font-family: $font-heading !important;
}

// Empty State
.se-empty-state__title {
  font-family: $font-heading !important;
}

// Membership Card
.se-membership-card-info__name {
  font-family: $font-heading !important;
}