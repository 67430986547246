.dropdown-date-picker {
  @include clear-after;

  &__month {
    float: left;
    width: 45%;
  }

  &__date {
    float: left;
    margin: 0 .5em;
    width: calc(30% - 1em);
  }

  &__year {
    float: left;
    width: 25%;
  }

}

.dropdown-date-picker--day-month-format {
  @include clear-after;

  &__date {
    float: left;
    width: calc(30% - 1em);
  }

  &__month {
    float: left;
    margin: 0 .5em;
    width: 45%;
  }

  &__year {
    float: left;
    width: 25%;
  }
}
