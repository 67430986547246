.pl-process-card {
  margin-top: 4px;
  background-color: #f0f2f4 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  box-shadow: none !important;

  &__text {
    align-items: center;
    margin: auto;
  }

  &__link {
    align-items: center;
    display: inline-block;
  }

  &__error-subtext {
    font-size: $font-xsmall;
  }

  &__button {
    align-items: center;
    border: none !important;
    display: flex;
  }

  &__icon {
    align-items: center;
    align-self: center;
    display: flex;
  }

  &__button-link {
    display: flex;
    border: none !important;
    padding: 7px 16px;
    align-self: center;
    margin: auto;
  }
}
