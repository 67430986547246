.pl-payout-summary {
  &-header {
    align-items: baseline;
    justify-content: center;
    border-bottom: 1px solid $gray-light;
    display: flex;
    padding: $layout-spacing-medium;

    &__amount {
      font-size: $font-large;
      font-weight: $font-semibold;
    }

    &__label {
      color: $color-gray-50;
      font-size: $font-small;
      margin-left: $layout-spacing-xsmall;
    }
  }

  &-table {
    margin-top: $layout-spacing-large;
    margin-bottom: $layout-spacing-xsmall;
  }

  @media (min-width: #{$tablet-width}) {
    &-content {
      padding: 0 5%;
    }
  }
}
