// Need to define these dimensions in px in order to line up with the dash background SVG
$filtering-and-or-button-width: 34px;
$filtering-and-or-width: 32px;
$filtering-and-or-height: 29px;
$filtering-center-padding: 27px;
$filtering-content-wrapper-padding: 10px;
$filtering-dashes-height: 62px;
$filtering-filter-height: 64px;
$filtering-readonly-or-block-left: 70px;
$filtering-readonly-or-block-top: 22px;
$filtering-readonly-or-block-width: 67px;
$filtering-rule-wrapper-height: 30px;
$filtering-filter-before-bg-position: -30px -10px;

//Animation on showing/hiding filter panel
.pl-filter-panel {
  max-height: 250px;
  overflow: auto;
  transition: .2s linear max-height;
}

.pl-filter-panel.ng-hide {
  border: 0;
  max-height: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.pl-filter-panel__container {
  opacity: 1;
  transition: opacity $duration ease-in $duration;

  .pl-link {
    @extend %pl-link;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.pl-filter-panel__container.ng-hide {
  opacity: 0;
  transition: opacity .1s ease-in;
}


// NOTE: this should go on a pl-pane element
.pl-filter__container {
  background: transparent;
  color: $color-content-lighter;
  margin-top: -$layout-spacing-small; //Remove margin from first filter rule
  overflow: visible !important;
  position: relative;
  z-index: 0; // lock this into a single plane

  &.pl-filter__container--empty {
    margin-top: 0; // except if the filter set is empty
  }
}

%pl-filter__before {
  background-color: transparent;
  background-image: url('#{$shared-image-path}/filter-dashes.svg?v2'); // cache was not gettign cleared
  background-position: $filtering-filter-before-bg-position;
  content: '';
  height: $filtering-dashes-height; // $layout-spacing-large is in rem, can't combine with px
  left: 0;
  position: absolute;
  width: $filtering-and-or-width - 2; // adjust for outline
  z-index: -1; // dashed lines should go below box shadows from previous elements
}

%pl-filter {
  background-color: $bg-default;
  border: 1px solid $gray-light;
  box-shadow: 0 1px 2px #ccc;
  display: flex;
  min-height: $filtering-filter-height;
  position: relative;

  &.pl-filter-invalid::before {
    height: 0;
  }

  &::before {
    @extend %pl-filter__before
  }

  .pl-addon-field__input {
    @include font-small; // re-override sn font size here
  }
}

.pl-filter {
  @extend %pl-filter;
  margin-top: $layout-spacing-small;
}

.pl-filter__hidden {
  visibility: hidden;
}

.pl-filter-button {
  position: relative;

  .pl-filter-button__caret {
    height: 10px;
    overflow: hidden;
    position: absolute;
    right: calc(50% - 10px);
    top: 38px;
    width: 20px;
    z-index: 1;
  }

  .pl-filter-button__caret::before {
    background: #f8f8f9;
    border: 1px solid lightgray;
    bottom: -6px;
    content: '';
    height: 12px;
    left: 5px;
    position: absolute;
    transform: rotate(45deg);
    width: 14px;
  }

  @media (max-width: $tablet-width) {
    .pl-filter-button__caret {
      display: none;
    }
  }
}

.pl-filter--or {
  @extend %pl-filter;
  margin: -1px 0 0 $filtering-and-or-width;

  &.pl-filter-invalid::before {
    height: $filtering-and-or-height;
  }

  &::before {
    left: -$filtering-and-or-width;
  }
}

.pl-filter__and-or {
  background-color: $color-gray-50;
  border-radius: 50%;
  color: $color-content-inverse;
  display: flex;
  font-size: 10px;
  height: $filtering-and-or-height;
  margin-left: $layout-spacing-medium;
  margin-top: ($filtering-filter-height - $filtering-and-or-height) / 2;
  text-transform: uppercase;
  width: $filtering-and-or-width;
  z-index: 1; // cover up the dashes

  .pl-filter__and-or-label {
    margin: auto;
  }
}

%pl-filter__content {
  align-self: flex-end;
  margin: 0 0 $layout-spacing-normal $layout-spacing-medium;
}

.pl-filter__content {
  @extend %pl-filter__content
}

%pl-filter-readonly {
  background-color: $bg-default;
}

.pl-filter-readonly {
  @extend %pl-filter-readonly;
}

.pl-filter-readonly--or {
  @extend %pl-filter-readonly;

  margin-left: 30px;
  position: relative;

  &::before {
    @extend %pl-filter__before;

    height: $filtering-and-or-height;
    left: -$filtering-readonly-or-block-left;
    top: -$filtering-readonly-or-block-top;
    width: $filtering-readonly-or-block-width;
  }
}

.pl-filter__content--readonly {
  color: $color-content;
  margin-right: 5px;
  overflow-x: hidden;
  white-space: nowrap;

  .pl-filter-rule-text {
    min-height: $filtering-rule-wrapper-height;
    text-transform: capitalize;
  }

  .pl-filter-inline-text {
    margin-right: 5px;
    text-transform: lowercase;
  }
}

.pl-filter-rule-wrapper {
  min-height: $filtering-rule-wrapper-height;
}

.pl-filter-survey-url {
  font-size: $layout-spacing-medium;
  margin: 5px 0;
  text-align: right;
}

.pl-filter-inline-text {
  color: $color-content;
}

.pl-filter__actions {
  background-color: $bg-default;
  display: flex;
  padding: 0 $layout-spacing-large;
}

.pl-filter__add-button {
  @extend %pl-button--minimal;
  font-weight: $font-regular;
  margin-left: 18px; // line up the + with the dashes
}

.pl-filter__and-or-button {
  @extend %button;
  @extend %button-synced-height;
  padding-left: 0;
  padding-right: 0;
  width: $filtering-and-or-button-width;
}

.pl-mobile-filters-search {
  &__label {
    display: block;
    position: relative;
    width: 100%;
  }

  &__input {
    padding: 12px 50px 12px 16px;
    width: 100%;
  }

  &__icon {
    height: 24px;
    position: absolute;
    right: 16px;
    top: calc(50% - 12px);
    width: 24px;
  }
}
