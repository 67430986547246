// # Media object
// Displays text with a small floating image.
// Borrowed from: http://goo.gl/oIxVyw

.pl-media,
%pl-media {
  @extend %content-spacing;
  display: flex;
  flex-direction: row;
}

.pl-media--reverse {
  @extend %pl-media;
}

.pl-media--centered {
  @extend %pl-media;
  justify-content: center;
}

.pl-media--vertically-centered {
  @extend %pl-media;
  align-items: center;
}

.pl-media--text-centered {
  text-align: center;
}

.pl-media__image,
%pl-media__image {
  flex-shrink: 0;
  margin-right: $layout-spacing-medium;

  .pl-media--reverse & {
    margin-left: $layout-spacing-medium;
    margin-right: 0;
    order: 2;
  }
  img {
    display: block;
    .pl-media--text-centered & {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .pl-media--text-centered & {
    margin-bottom: $layout-spacing-small;
    margin-left: 0;
    margin-right: 0;
  }
}

.pl-media__image--round {
  @extend %pl-media__image;

  img {
    border-radius: 50%;
    overflow: hidden;
  }
}
