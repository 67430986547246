.pl-accordion-list {
  border-collapse: collapse;
  display: table;
  list-style: none;
  margin: -1px; // suck the border into accompanying space
  width: calc(100% + 2px); // account for border

  .pl-accordion-list__item {
    border: 1px solid $gray-light;
    display: table-row;

    &.pl-is-expanded {
      > .pl-accordion-list__item-header {
        border-bottom: 1px solid $gray-light;

        &::after {
          transform: none;
        }
      }
      > .pl-accordion-list__item-content {
        display: block;
      }
    }
  }

  .pl-accordion-list__item-header {
    background-color: $bg-default;
    cursor: pointer;
    font-family: $font-heading;
    font-weight: $font-regular;
    margin: 0;
    padding: $layout-spacing-medium $layout-spacing-large;
    user-select: none;

    .pl-option__label {
      pointer-events: none;

      &::before {
        pointer-events: all;
      }
    }

    &:hover {
      box-shadow: inset 0 0 .5em $color-highlight-light;
    }

    &::after {
      background-image: url('#{$shared-image-path}/chevron-down.svg');
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      height: 16px;
      margin-left: 4px; // Safari gives no spacing on the left otherwise
      transform: rotate(-90deg);
      transition: transform $duration;
      width: 16px;
    }
  }

  .pl-accordion-list__item-content {
    display: none;
    padding: $layout-spacing-large;
  }

  @include padding-modifiers('.pl-accordion-list__item-content');
}
