// # Forms
// Default, generic styles for form elements


// ## Field
// Single-line text inputs
%pl-field {
  background: $field-background;
  border: $field-border;
  border-radius: $field-border-radius;
  color: $field-color;
  font-family: $field-font-family;
  font-weight: $field-font-weight;
  line-height: $field-line-height;
  padding: $field-padding;

  &:hover {
    border-color: $field-hover-border-color;
    z-index: $z-grouped-field--hover;
  }
  &:focus {
    border-color: $field-focus-border-color;
    box-shadow: $field-focus-box-shadow;
    outline: none;
    z-index: $z-grouped-field--focus;
  }
  &[disabled],
  &:disabled {
    border-color: $field-disabled-border-color;
    color: $field-disabled-color;
    cursor: default;
  }
  &::placeholder {
    color: $field-placeholder-color;
  }
  &::-ms-clear {
    display: none;
  }
  &.pl-has-error {
    border-color: $field-error-border-color;

    &:focus {
      box-shadow: $field-error-box-shadow;
    }
  }
}
.pl-field {
  @extend %pl-field;
  font-size: $field-font-size;
}
.pl-field--full {
  @extend %pl-field;
  font-size: $field-font-size;
  width: 100%;
}
.pl-field--large {
  @extend %pl-field;
  font-size: $field-large-font-size;
  line-height: $field-large-line-height;
  padding: $field-large-padding;
}
.pl-field--number {
  @extend .pl-field;
  font-size: $field-font-size;
  padding-right: $field-number-padding-right;  // even out display of up/down control
}

.pl-field--inline-margin-x {
  margin: 0 8px;
}

.pl-field__content {
  display: block;

  + .pl-field__content {
    margin-top: $layout-spacing-small;
  }
}

.pl-sr-only {   // used for elements that need to be visible for screen readers only
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// ## Field containers
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

%pl-field__container {
  margin-top: .8571rem; // 12px / 14px

  &:first-child > .pl-field__label--large:first-child {
    padding-top: 0;
  }

  .pl-field__label--large + & {
    margin-top: 0 !important; // remove extra spacing when inside a fieldset
  }

  @include media-small {
    margin-top: 1.4286rem; // 20px / 14px
  }
}

.pl-field__container {
  @extend %pl-field__container;
}

.pl-field__container--collapse {
  @extend %pl-field__container;
  margin: 0 !important;
}

.pl-field__narrow {
  font-size: $field-narrow-font-size;
  margin: 0 !important;
}


// ## Field labels

%pl-required-indicator {
  color: $field-label-required-color;
  content: ' * ';
  font-weight: $field-label-required-font-weight;
}

.pl-required-help::before {
  @extend %pl-required-indicator;
}

label[for] {
  cursor: pointer;
}
.pl-field__label,
%field__label {
  @include font-xxsmall;
  color: $field-label-color;
  display: block;
  padding-bottom: $field-label-padding-bottom;

  @include media-small {
    @include font-small;
  }
}
.pl-field__label--large {
  @include font-medium;
  color: $field-label-large-color;
  display: block;
  padding: $field-label-large-padding;
}
.pl-field__label--small,
%pl-field__label--small {
  @include font-xsmall;
  color: $field-label-small-color;
  display: block;
  padding-bottom: $field-label-padding-bottom;
}
.pl-field__label--small--required {
  @extend %pl-field__label--small;
  &::after {
    @extend %pl-required-indicator;
  }
}
.pl-field__label--inline {
  color: $field-label-color;
  display: inline-block;
}
.pl-field__label--offscreen {
  left: -9999em;
  position: absolute;
}
.pl-field__label--required {
  @extend %field__label;

  &::after {
    @extend %pl-required-indicator;
  }
}


// ## Field help text

%field__help {
  @include font-xxsmall;
  color: $field-help-color;
  padding-top: $field-help-padding-top;

  .pl-option + & {
    padding-top: $field-help-option-padding-top; // matches space between options
  }

  @include media-small {
    @include font-small;
  }
}

.pl-field__help {
  @extend %field__help;

  @include media-small {
    color: $field-label-color;
  }
}

.pl-field__help--alert {
  @extend %field__help;
  color: $field-help-alert-color;
}

.pl-field__help--inline {
  color: $field-label-color;
}

.pl-field__hint {
  @include font-xxsmall;
  color: $field-label-color;
}


// ## Select
$select-arrow: url('#{$shared-image-path}/chevron-down.svg');

%select {
  appearance: none;
  background-color: $select-background-color;
  background-image: $select-background-image;
  background-position: $select-background-position;
  background-repeat: no-repeat, repeat-x;
  border: $select-border;
  border-radius: $select-border-radius;
  cursor: pointer;
  outline: none;
  padding: $select-padding;

  &:focus:not(:active):not([disabled]) {
    border-color: $select-focus-border-color;
    box-shadow: $select-focus-box-shadow;
  }
  &:hover:not([disabled]) {
    background-image: $select-hover-background-image;
    border-color: $select-hover-border-color;
  }
  &[disabled] {
    background-color: $select-disabled-background-color;
    background-image: $select-disabled-background-image;
    border-color: $select-disabled-border-color;
    color: $select-disabled-color;
    cursor: default;
  }
  // Removes blue highlight on focused selects in IE 10 & 11
  &:focus::-ms-value {
    background-color: transparent;
    color: black;
  }
  // Hides default select arrow in IE 10 & 11
  &::-ms-expand {
    display: none;
  }
  &.pl-has-error:not(:active):not([disabled]) {
    border-color: $select-error-border-color;

    &:focus {
      box-shadow: $select-error-box-shadow;
    }
  }
}
.pl-select {
  @extend %select;
  font-size: $select-font-size;
}
.pl-select--full {
  @extend %select;
  font-size: $select-font-size;
  width: 100%;
}
.pl-select--large {
  @extend %select;
  font-size: $select-large-font-size;
}


// ## Textarea
.pl-textarea,
%pl-textarea {
  @extend %pl-field; // dangerous? too many styles to repeat (focus, hover, disabled...)
  font-size: $text-area-font-size;
  resize: both;
}
.pl-textarea--resize-x {
  @extend %pl-textarea;
  resize: horizontal;
}
.pl-textarea--resize-y {
  @extend %pl-textarea;
  resize: vertical;
}
.pl-textarea--resize-none {
  @extend %pl-textarea;
  resize: none;
}
.pl-textarea--full {
  @extend %pl-textarea;
  width: 100%;
}

// ## Form actions

$pl-form-actions-spacing--condensed: $layout-spacing-medium / 2;
$form-actions-button-spacing: 4px;

.pl-form-actions,
%pl-form-actions {
  align-items: center;
  background: rgba(255, 255, 255, .8); // for when it's sticky
  border-top: 1px solid $gray-light;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: $layout-spacing-small / 2;

  [class*='pl-subsection--'] + & {
    margin-top: -1px; // prevent double border
  }

  > * {
    padding: $layout-spacing-small / 2;
  }

  @include media-small {
    flex-wrap: nowrap;
    // combined with "cell" spacing, this results in "large" spacing orund the inner elements
    padding: $layout-spacing-large - $layout-spacing-small / 2;
  }
}

%pl-form-actions--condensed {
  @extend %pl-form-actions;
  padding: $pl-form-actions-spacing--condensed;

  > * {
    padding: $pl-form-actions-spacing--condensed;
  }

  @include media-small {
    padding: ($pl-form-actions-spacing--condensed) ($layout-spacing-large - $pl-form-actions-spacing--condensed);
  }
}

.pl-form-actions--condensed {
  @extend %pl-form-actions--condensed;
}

.pl-form-actions--cta {
  @extend %pl-form-actions--condensed;
  display: block;
}

.pl-form-actions--auto {
  @extend %pl-form-actions--condensed;
  flex-wrap: nowrap;

  .pl-form-actions__info {
    color: $color-content-light;
    flex: 1 100 auto;
  }

  .pl-form-actions__button-container {
    flex: 100 1 auto;
    justify-content: flex-end;
    width: auto;
  }

  .pl-form-actions__button {
    flex: 0 0 auto;
  }
}

.pl-form-actions__info {
  flex: 1 1 100%;

  @include media-small {
    flex: 1 100 auto;
  }
}

.pl-form-actions__button-container {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  @include media-small {
    flex: 100 1 auto;
    justify-content: flex-end;
    width: auto;
  }
}

.pl-form-actions__button-container--cta {
  display: block;
  .pl-form-actions__button {
    display: block;
  }
  @include media-small {
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    .pl-form-actions__button {
      flex: 1 0 auto;
    }
  }
}

%pl-form-actions__button {
  border-color: transparent;
  flex: 1 0 auto;
  margin: $form-actions-button-spacing;

  // this as actually styles for larger screens
  @include media-small {
    flex: 0 0 auto;
  }
}

.pl-form-actions__button {
  @extend %button;
  @extend %pl-form-actions__button;
}

// Catch buttons that do not yet have the new class
.pl-form-actions__button-container [class*='pl-button'] {
  @extend %pl-form-actions__button;
}

// ## Field icon
.pl-field-icon__container {
  display: flex;
  flex-direction: row;

  > * {
    // Fix for flickering bug caused by flex/z-index combination
    // Issue in Chrome 52 and Sarari 9.1.1
    z-index: 1;
  }
}
.pl-field-icon__button,
%pl-field-icon__button {
  background: transparent;
  border: 0;
  margin-left: $field-icon-button-margin-left;
  padding: 0;
}
.pl-field-icon__button--large {
  @extend %pl-field-icon__button;
  font-size: $field-icon-button-large-font-size;
}

.pl-form-container {
  max-width: 400px;
}

.pl-team-bag {
  background-color: $gray-xxlight;
  border: solid 1px $gray-light;
  border-left: solid 5px $color-highlight;
  border-radius: $input-border-radius;
  padding: $layout-spacing-medium;
}
.pl-team-bag__header {
  color: $color-gray-85;
  font-size: $font-small;
  font-weight: $font-semibold;
}

.pl-content-block,
%pl-content-block {
  background: rgba(0, 0, 0, .05);
  border-radius: $input-border-radius;
  margin: $layout-spacing-small 0;
  padding: $layout-spacing-small;
}

.pl-content-block--indent {
  @extend %pl-content-block;
  margin-left: $layout-spacing-normal;
}
