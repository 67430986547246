.pl-squadlocker__spacer { // pseudo-centering spacer at the top of the setup page
  height: calc((100% - 362px) / 2); // half of the page height minus approximate content height minus ngin bar height
  max-height: 96px;
}
.pl-squadlocker__panel {
  background: $squadlocker-bkg-color;
  color: $color-content-light;
  padding: 20px 24px;
  text-align: center;
}
.pl-squadlocker__panel--footer {
  padding-top: 20px;
  text-transform: none !important;
}
.pl-squadlocker__title {
  @extend %heading;
  color: $squadlocker-title-color;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.4;
  text-align: center;
}
.pl-squadlocker__subtitle {
  color: $color-content-light;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.67;
  text-align: center;
}
.pl-squadlocker__data {
  color: $squadlocker-title-color;
  display: block;
  font-size: 24px;
  line-height: 1.33;
}
.pl-squadlocker__label {
  color: $color-content-light;
  display: block;
  font-size: 14px;
  line-height: 1.14;
  margin-top: 24px;
}
.pl-squadlocker__button--highlight {
  background-color: $squadlocker-button-color;
  border: 1px solid $squadlocker-button-highlight-border-color;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.43;
  padding: .3857rem .9571rem;
  text-align: center;

  &:disabled {
    background: white;
    border-color: $squadlocker-button-border-color;
    color: $color-content-light;
    cursor: default;
    opacity: .3;
  }
}
.pl-squadlocker__button {
  background-image: linear-gradient(to bottom, white, $squadlocker-bkg-color);
  border: 1px solid $squadlocker-button-border-color;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin-left: 12px;
  padding: .3857rem .9571rem;

  &:disabled {
    cursor: default;
    opacity: .3;
  }
}
.pl-squadlocker__icon-container {
  max-height: 60px;
  width: 120px;
}
.pl-squadlocker__row {
  margin-bottom: 0 !important;
}
.pl-squadlocker__logo {
  border: solid 1px #d4d9dc;
  box-shadow: 0 1px 2px 0 rgba(204, 204, 204, .5);
  padding: 0;
  width: 165px;
  .upload {
    height: 27px;
    margin: 0 0 .5em;
    width: 22px;
  }
  .pl-card-add {
    background-color: #fbfbfc;
  }
}
.logo-container {
  .pl-media__image {
    padding: 12px;
    img {
      border-width: 0;
    }
  }
  .pl-document-upload__view-image {
    border-width: 0;
    height: 128px;
    margin: 0;
    padding: 0;
    width: 128px;
  }
}
@media(min-width: $media-desktop + 1) {
  .pl-squadlocker__panel .pl-squadlocker__subtitle {
    font-size: 14px;
  }
}
.store-preview {
  width: 500px;
  .content-title {
    @extend %content-spacing;
    color: #23282b;
    line-height: $font-leading-title;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}
