.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;

  .select2-results {
    display: block;
  }

  .select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;

    .select2-group_delimiter hr {
      margin: 10px 0;
    }
  }

  .select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none;

    .select2-unindexed-item {
      color: $color-content-light;
    }

    &[aria-selected] {
      cursor: pointer;
    }

    &.extra-option-placeholder {
      text-align: center;
    }
  }
}

.select2-container--open {
  max-width: 100vw;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}

.select2-custom-loading-results {
  align-items: center;
  border-top: 1px solid rgb(212, 216, 220);
  display: flex;  
  height: 45px;
  list-style: none;

  .loading-message {
    margin-left: 20px;
  }
}

.select2-custom-searching-results {
  @extend .select2-custom-loading-results;
  padding: 0 !important;
}