// # User Avatar
$user-avatar-edit-border-radius: 60px;

%pl-user-avatar,
.pl-user-avatar {
  overflow: hidden;
  text-align: center;
}

.pl-user-avatar__wrapper {
  position: relative;
}

.pl-user-avatar__image,
.pl-user-avatar__image--custom {
  background-clip: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: block;
  height: 110px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 110px;
  z-index: 0; // force overflow hidden when used with sn theme (no idea why this is needed)
}

.pl-user-avatar__image {
  background-image: url('#{$shared-image-path}/avatar.svg');
}

.pl-user-avatar__edit-actions {
  background: $color-content-xlight;
  border-bottom-left-radius: $user-avatar-edit-border-radius;
  border-bottom-right-radius: $user-avatar-edit-border-radius;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 100;

  button {
    background: none;
    border: 0;
    display: block;
    margin: 0 auto;
    opacity: .5;
    padding: .1em;

    &:hover {
      color: #fff;
      opacity: 1;
    }
    &:focus {
      outline: 1px dotted;
    }
  }

  svg {
    fill: #fff;
    height: 1.3rem;
    width: 1.3rem;
  }
}

// ### sizes
.pl-user-avatar--large {
  @extend %pl-user-avatar;
  .pl-user-avatar__image,
  .pl-user-avatar__image--custom {
    height: 72px;
    width: 72px;
  }
}

.pl-user-avatar--medium {
  @extend %pl-user-avatar;
  .pl-user-avatar__image,
  .pl-user-avatar__image--custom {
    height: 48px;
    width: 48px;
  }
}

.pl-user-avatar--small {
  @extend %pl-user-avatar;
  .pl-user-avatar__image,
  .pl-user-avatar__image--custom {
    height: 40px;
    width: 40px;
  }
}
