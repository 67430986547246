.pl-accordion-link {
  background-image: url('#{$shared-image-path}/chevron-down.svg');
  background-position: 100% calc(#{$font-leading-body / 2}em - 8px);
  background-repeat: no-repeat;
  color: $color-content-light;
  cursor: pointer;
  display: block;
  padding-right: 24px;

  &.is-active {
    background-image: url('#{$shared-image-path}/chevron-up.svg');
  }
}

.pl-accordion-link__content {
  display: none;

  &.is-active {
    display: block;
  }
}
