// # Side nav
// Vertical navigation presented in an off-screen menu for small screens and
// shown on the left side of the screen for large screens.

$side-nav-avatar-size: 48px;

// The side-nav background color fills at least the full document/body height
// Takes up more height if its contents are taller than the body
.pl-side-nav {
  background-clip: padding-box;
  border-right: 1px solid $color-content-xxlight;
  flex-direction: column;
  position: relative;
  width: $side-nav-width;

  &,
  [class*='pl-pane'] {
    background-color: $gray-xlight; // panes are white by default
  }

  // Always show nav if screen is not small
  @media (min-width: $media-desktop) {
    &.pl-is-open,
    &:not(.pl-is-open) {
      display: flex !important;
    }
  }
}

// These classes only apply to full scrolling pages. This may be temporary.
.pl-has-side-nav .pl-side-nav {
  min-height: 100%;
  padding-top: $ngin-bar-height;
  position: absolute;
  top: 0;
  z-index: 1; // Position below ngin-bar

  &.pl-is-open {
    display: flex;
  }
  &:not(.pl-is-open) {
    display: none;
  }
}

.pl-side-nav--popover {
  border-right: 1px solid $gray-light;
  bottom: 0;
  left: 0 - 1 - $side-nav-width;
  position: absolute;
  top: 0;
  transition: 300ms;
  width: $side-nav-width;
  z-index: $z-popup;

  &.showing {
    left: 0;
  }
}

.pl-side-nav__toggle {
  @include media-small {
    display: none;
  }
}

.pl-side-nav__logo-container {
  background-color: #fff;
  border-radius: 999px;
  display: block;
  height: 7.1429em; // 100px / 14px
  margin: auto;
  padding: 1.4286em; // 20px / 14px
  width: 7.1429em; // 100px / 14px
}

.pl-side-nav__logo {
  display: block;
  width: 100%;
}

.pl-side-nav__title {
  @include font-medium;
  margin-top: 12px;
  text-align: center;
}

.pl-side-nav__description {
  color: $color-content-light;
  text-align: center;
}

.pl-side-nav__section,
%pl-side-nav__section {
  padding-bottom: 1.4286em; // 20px / 14px
  padding-top: 1.4286em; // 20px / 14px

  & + & {
    border-top: solid 1px $color-content-xxlight;
  }
}

.pl-side-nav__section--padded {
  @extend %pl-side-nav__section;
  padding-left: 1.4286em; // 20px / 14px
  padding-right: 1.4286em; // 20px / 14px
}

.pl-side-nav__section-heading {
  @include font-small;
  font-weight: $font-semibold;
  margin-bottom: .5714em; // 8px / 14px
  padding-left: 1.7143em; // 24px / 14px
  padding-right: 1.7143em; // 24px / 14px
}

// Mobile collapsed menu

@media (max-width: $media-small) {

  .pl-side-nav {
    #{'-webkit-overflow-scrolling'}: touch;
    border: 1px solid transparent; // border causes background to fill durring rubber band scrolling
    box-shadow: 0 150px 0 #fff, 0 -150px 0 #fff; // extend the white outside the scroll area to account for ios chrome collapsing and rubber band scrolling
    display: none;
    height: calc(100% - #{$ngin-bar-height});
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    z-index: $ngin-bar-z - 1;

    &,
    [class*='pl-pane'] {
      background-color: #fff; // panes are white by default
    }
  }

  .nb-mobile-nav-open {
    height: 100% !important;
    overflow: hidden !important;

    .pl-side-nav {
      display: flex;
    }
  }
}

// Mobile User Avatar -- account for rotated devices (give more space to the menu items)
@media (max-width: #{$tablet-width - 1}), (max-height: #{$tablet-width - 1}) {
  .pl-side-nav {

    .pl-user-avatar {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;

      &__image,
      &__image--custom {
        flex: 0 0 $side-nav-avatar-size;
        height: $side-nav-avatar-size;
        margin: 0 $layout-spacing-medium 0 0;
        width: $side-nav-avatar-size;
      }

      &__name {
        @include font-medium;
        font-family: $font-heading;
        font-weight: $font-regular;
        margin: 0;
        text-align: left;
      }
    }
  }
}
