// # Addon Field
// Markup pattern to visually attatch arbitrary icons and labels to text fields.

.pl-addon-field,
%pl-addon-field {
  align-items: center;
  background: $addon-field-background;
  border: $addon-field-border;
  border-radius: $addon-field-border-radius;
  color: $addon-field-color;
  display: inline-flex;
  font-family: $addon-field-font-family;
  font-weight: $addon-field-font-weight;
  justify-content: center;
  line-height: $field-line-height;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::placeholder {
    color: $addon-field-placeholder-color;
  }
  &:hover {
    border-color: $addon-field-hover-border-color;
    z-index: 2;
  }
  &.pl-has-focus {
    border-color: $addon-field-border-color;
    border-radius: $addon-field-border-radius;
    box-shadow: $addon-field-box-shadow;
    outline: none;
    z-index: 3;
  }
  &.pl-has-error {
    border-color: $addon-field-error-border-color;
    &.pl-has-focus {
      box-shadow: 0 0 0 1px $addon-field-error-border-color;
    }
    &:not(.pl-has-focus) {
      z-index: 1;
    }
  }
  &.disabled {
    color: $addon-field-disabled-border-color;
    cursor: default;
    &:hover {
      border-color: $addon-field-disabled-border-color;
    }
  }
}

.pl-addon-field__input {
  border: 0;
  flex: 1 1 auto;
  font-size: $addon-field-input-font-size; // $font-small
  line-height: $addon-field-input-line-height; // $font-leading-body
  padding: $addon-field-input-padding;
  width: 0; // flex basis overrides this, but it was necessary to prevent FF 47 from failing
  &::-ms-clear {
    display: none;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $addon-field-placeholder-color;
  }
  &.disabled {
    cursor: default;
  }
}

.pl-addon-field__icon {
  background: $addon-field-icon-background;
  border-right: $addon-field-icon-border-right;
  color: $addon-field-icon-color;
  flex: 0 0 auto;
  padding: $addon-field-icon-padding;

  &[disabled],
  &:disabled,
  &.disabled {
    cursor: default;
    color: $addon-field-disabled-border-color;
  }
}

%pl-addon-field__extra {
  flex: 0 0 auto;
  padding-right: $addon-field-extra-padding-right;

  .pl-icon {
    cursor: pointer;
    margin-right: 0;
  }
}

.pl-addon-field__label {
  @extend %pl-addon-field__extra;
  color: $addon-field-label-color;
  cursor: text;

  &[disabled],
  &:disabled,
  &.disabled {
    cursor: default;
  }
}

.pl-addon-field__link {
  @extend %pl-link;
  @extend %pl-addon-field__extra;
}

.pl-addon-field--large {
  @extend %pl-addon-field;
  @include font-large;
}

.pl-addon-field--fixed {
  @extend %pl-addon-field;
  width: 125px;
}
