$pl-icon-button-icon-size: 1.5em;
$pl-icon-button-icon-padding: 3px;
$pl-icon-button-icon-spacing: .5em;
$pl-icon-button-icon-wrapper-size: calc(#{$pl-icon-button-icon-size} + #{$pl-icon-button-icon-padding * 2});
$pl-icon-button-text-padding: calc(#{$pl-icon-button-icon-size} + #{$pl-icon-button-icon-padding * 2} + #{$pl-icon-button-icon-spacing * 2});

%pl-icon-button__icon {
  border-radius: 50%;
  height: $pl-icon-button-icon-wrapper-size;
  left: $pl-icon-button-icon-spacing;
  margin-top: calc(#{-$pl-icon-button-icon-padding} - #{$pl-icon-button-icon-size / 2});
  padding: $pl-icon-button-icon-padding;
  position: absolute;
  top: 50%;
  width: $pl-icon-button-icon-wrapper-size;

  > * {
    display: block;
    fill: currentColor;
    height: 100%;
    width: 100%;
  }
}

.pl-icon-button {
  @extend %button;
  padding-left: $pl-icon-button-text-padding;
  padding-right: $pl-icon-button-text-padding;
  position: relative;
  width: 100%;
}

.pl-icon-button__icon {
  @extend %pl-icon-button__icon;
}

.pl-icon-button__icon--reversed {
  @extend %pl-icon-button__icon;
  background-color: $color-content-inverse;

  .pl-button--highlight & {
    color: $color-highlight;
  }

  .pl-button--primary & {
    color: $color-success;
  }
}


