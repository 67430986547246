// # Variables
// Uses font-size and EMs so image width can be relative to height of container

$pl-card-spacing: 24px;
$pl-card-padding: $layout-spacing-large;
$pl-card-padding-horiz: 15px;
$pl-card-width: 250px;
$pl-card-width--horiz: 350px;
$pl-card-avatar--height: 109px;
$pl-card-avatar--img-border-width: 3px;
$pl-card-avatar--img-height: ceil($pl-card-avatar--height * .66);
$pl-card-avatar--img-height-horiz: 72px;
$pl-card-avatar--img-width-horiz: 72px;
$pl-card-avatar--img-top: $pl-card-avatar--height - $pl-card-avatar--img-height;
$pl-card-avatar--img-text-size: 18px;
$pl-card-avatar--img-text-offset: $pl-card-avatar--img-height - $pl-card-avatar--img-text-size / 2 + $pl-card-avatar--img-border-width;
$pl-card-avatar--background-height: $pl-card-avatar--img-top + $pl-card-avatar--img-height / 2;
$pl-card-avatar--background-offset: 20px;
$pl-card-avatar--close-offset: 6px;
$pl-card-detail--group-padding-left: 15px;
$pl-card-detail--group-width: 192px;

// # Profile Cards

%pl-card {
  background: #fff;
  box-shadow: 0 1px 2px #ccc;
  display: block;
  margin: $pl-card-spacing / 2;
  position: relative;
}

.pl-card {
  @extend %pl-card;
  width: $pl-card-width;
}

.pl-card--horiz {
  @extend %pl-card;
  display: inline-flex;
  flex-wrap: nowrap;
  width: $pl-card-width--horiz;

  .pl-card-avatar {
    background: $color-content-xxxlight;
    flex: 0 0 auto;
    margin-bottom: 0;
    padding: $pl-card-padding-horiz;
  }

  .pl-card-avatar__image {
    height: $pl-card-avatar--img-height-horiz;
    margin: 0;
    position: static;
    width: $pl-card-avatar--img-width-horiz;
  }

  .pl-card-detail__group {
    flex: 1 1 50%; // percentage width will force all groups to be even widths since this is set to nowrap
    padding: calc(#{$pl-card-padding} - .2em) $pl-card-padding;
    padding-left: $pl-card-detail--group-padding-left;
    width: $pl-card-detail--group-width;
  }

  .pl-card-detail--primary--nowrap {
    border: 0;
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    overflow: hidden;
    padding: 0;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.pl-card--full-height {
  @extend %pl-card;
  align-items: stretch;
  display: flex;
  width: $pl-card-width;
}


// ## Group of cards in a grid

.pl-card__group {
  display: flex;
  flex-wrap: wrap;
  padding: $pl-card-spacing / 2 !important; // override when used with panes
}


// ## Circular photo and blurred background photo

%pl-card__close,
.pl-card__close {
  @extend %pl-close;
  color: $color-content-light;
  position: absolute;
  right: $pl-card-avatar--close-offset;
  top: $pl-card-avatar--close-offset;
  z-index: 4;
}

.pl-card__close-reversed {
  @extend %pl-card__close;
  color: #fff;
}

.pl-card-avatar {
  display: block;
  margin-bottom: -$pl-card-avatar--img-border-width; // spacing below should start from the image content
  overflow: hidden;
  padding-bottom: $pl-card-avatar--img-height / 2;
  position: relative;
}

.pl-card-avatar__background {
  height: $pl-card-avatar--background-height;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::after {
    background: rgba(0, 0, 0, .3);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  // Treat custom background colors differently
  &[style*='background'] {
    .pl-card-avatar__background-image {
      mix-blend-mode: luminosity; // for browsers that support it.
      opacity: .5;
    }

    &::after {
      display: none; // remove black overlay since we have a colored background
    }
  }
}

.pl-card-avatar__no-background {
  height: $pl-card-avatar--background-height;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.pl-card-avatar__background-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px);
  height: $pl-card-avatar--background-height + $pl-card-avatar--background-offset * 2;
  margin: -$pl-card-avatar--background-offset;
  width: calc(100% + #{$pl-card-avatar--background-offset * 2});
  z-index: -1;
}

.pl-card-avatar__image {
  border-radius: $pl-card-avatar--img-height / 2;
  box-shadow: 0 0 0 2px #fff;
  height: $pl-card-avatar--img-height;
  left: 50%;
  margin-left: round(-$pl-card-avatar--img-height / 2);
  overflow: hidden;
  position: absolute;
  top: $pl-card-avatar--img-top;
  width: $pl-card-avatar--img-height;
  z-index: 3;
}

.pl-card-avatar__org-image {
  @extend .pl-card-avatar__image;
  color: #fff;
  cursor: default;
  font-size: $pl-card-avatar--img-text-size;
  line-height: $pl-card-avatar--img-text-offset;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
}

.pl-card-avatar__org-image--reversed {
  @extend .pl-card-avatar__org-image;
  color: #000;
}

.pl-card-avatar__org-image-bg {
  background-color: #fff;
  height: $pl-card-avatar--img-height;
  left: 0;
  position: absolute;
  top: 0;
  width: $pl-card-avatar--img-height;
  z-index: -1;
}

.pl-card-avatar__banner {
  background: $color-warn;
  color: $color-content-inverse;
  font-size: $font-xsmall;
  left: calc(37px - 50%);
  padding: .1em;
  position: absolute;
  text-align: center;
  top: 23px;
  transform: rotate(-35deg);
  width: 100%;
  z-index: 1;
}


// ## Title/subtitle and label/value content within a card

$pl-card-detail--icon-size: 14px;

.pl-card-detail__group {
  padding: calc(#{$pl-card-padding} - .2em) $pl-card-padding;
}

%pl-card-detail {
  border-bottom: 1px solid $color-content-xxlight;
  display: block;
  margin-top: calc(#{$pl-card-padding / 2} - .2em);
  padding-bottom: calc(#{$pl-card-padding / 2} - .2em);
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .pl-card-detail__rsvp-icons {
    margin: 20px 10px 0;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 10px;
    }
  }
}

.pl-card-detail {
  @extend %pl-card-detail;
}

.pl-card-detail--primary {
  @extend %pl-card-detail;
  font-size: $font-medium;
  margin-top: calc(#{$pl-card-padding} - .2em);
  padding-bottom: calc(#{$pl-card-padding} - .2em);
  text-align: center;
}

.pl-card-detail--icon {
  @extend %pl-card-detail;
  padding-left: calc(#{$pl-card-padding} + #{$pl-card-detail--icon-size});
  position: relative;
}

.pl-card-detail__icon {
  fill: $color-content-lighter;
  height: $pl-card-detail--icon-size;
  left: 0;
  position: absolute;
  top: .1em;
  width: $pl-card-detail--icon-size;
}

.pl-card-detail__title {
  @extend %pl-heading--medium;
  margin-bottom: 0;
  text-align: center;
}

.pl-card-detail__subtitle {
  color: $color-content-light;
  margin-top: .25em;
  text-align: center;
}

%pl-card-detail__label {
  color: $color-content-light;
  font-size: $font-xsmall;
  margin-bottom: 0;
}

.pl-card-detail__label {
  @extend %pl-card-detail__label;
}

.pl-card-detail__label--cut-off {
  @extend %pl-card-detail__label;
  max-height: $font-leading-body * 1em;
  overflow: hidden;
  word-wrap: break-word;
}

%pl-card-detail__value {
  font-size: $font-xsmall;
  margin-top: .25em;
}

.pl-card-detail__value {
  @extend %pl-card-detail__value;
}

.pl-card-detail__value--nowrap {
  @extend %pl-card-detail__value;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}


// ## Add link

.pl-card-add {
  align-items: center;
  background: #fff;
  color: $color-content-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.5em 0;
  text-decoration: none;
  width: 100%;
}

.pl-card-add__icon {
  color: $color-content-lighter;
  fill: currentColor;
  height: 75px;
  margin: 5em 0 4em;
  width: 75px;
}


// ## Styles for dragging/dropping cards, based on: http://y8m8sc.axshare.com/#g=1&p=home&c=1
.pl-card--draggable {
  cursor: move; // Safari doesn't like having this on the pseudo-element

  &:focus-within, button:focus{
    border-color: $color-nbc-blue;
    border-style: dotted;
    border-width: thin;
  }

  // Creates a drag handle effect using white borders.
  &:hover::before {
    border-bottom: 2px solid #fff;
    border-top: 6px double #fff;
    content: '';
    display: block;
    height: 2px;
    left: calc(50% - 10px); // center
    position: absolute;
    top: 5px;
    width: 20px;
    z-index: 3;
  }

  &,
  .pl-card-avatar__background-image,
  .pl-card-avatar__background::after {
    transition-duration: $duration;
    transition-property: background, opacity;
  }
}

.pl-card__drag-object {
  opacity: .3;
  pointer-events: none; // avoids the hassle of trying not to drop on itself
}

.pl-card__drag-target {
  outline: 1px dashed $color-content-xlight;

  * {
    cursor: move; // override <a> styles
  }
  .pl-card-avatar__background-image {
    opacity: 0;
  }
  .pl-card-avatar__background::after {
    background: #fff;
  }
  %pl-card-detail {
    border-bottom-style: dashed;
  }
}

.pl-card__drag-image {
  @extend .pl-card--horiz;
  align-items: center;
  margin: 0;
  pointer-events: none; // allows mouseenter/leave/move/up to occur on the elements beneath the drag image
  position: absolute;
  width: auto;
  z-index: 10;

  .pl-card-avatar__image {
    height: 48px;
    width: 48px;
  }
  .pl-card-detail__group {
    min-width: $pl-card-detail--group-width;
    width: auto;
  }
}

.pl-contacts-bar-card__group {
  margin-top: 0;

  .pl-card--horiz {
    height: 107px;
    margin: 0;
    width: 100%;

    .pl-card-avatar {
      padding: 18px;
    }

    .pl-contact-card-avatar__image {
      background: #fff url('#{$shared-image-path}/contact-avatar.svg') no-repeat 50%;
      background-size: cover;
    }

    .pl-card-detail__group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 0;
      padding: 18px;
      padding-left: $layout-spacing-large;

      .pl-card-detail__title {
        line-height: 28px;
        margin-top: -9px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
      }

      .pl-card-detail__subtitle {
        font-size: 16px;
        text-align: left;
      }

      .pl-card__close {

        &::before {
          font-size: 12px;
        }
      }
    }
  }


  .pl-add-contact-card {
    border: 1px dashed #ccc;
    cursor: pointer;
    opacity: .5;

    &:hover {
      opacity: 1;
    }

    .pl-card-detail__group {
      justify-content: center;

      .pl-card-detail__title {
        margin: 0;
      }
    }

    .plus-icon {
      bottom: 35px;
      color: $color-success-dark;
      height: 23px;
      position: absolute;
      right: 20px;
      width: 23px;
    }
  }

  .pl-show-more-contacts-card {
    cursor: pointer;

    .pl-show-more-contacts-card-avatar__image {
      background: #fff url('#{$shared-image-path}/see-more-contacts.svg') no-repeat 50%;
      background-size: cover;
    }

    .pl-card-detail__group {
      justify-content: center;
    }
  }
}

@media (min-width: 1024px) {

  .pl-contacts-bar-card__group {
    flex-wrap: nowrap;
  }

}

.pl-contacts-card__list--vert {

  .pl-add-profile__image {
    background: url('#{$shared-image-path}/plus-with-circle.svg') no-repeat 50%;
    background-size: cover;
    height: 46px;
    width: 56px;
  }

  .pl-card-avatar__image {
    background: #fff url('#{$shared-image-path}/contact-avatar.svg') no-repeat 50%;
    background-size: cover;
    height: 45px;
    margin: 0;
    position: initial;
    width: 45px;
  }

  .pl-list-grid__item {
    color: inherit;
  }

  .pl-card-detail__label {
    line-height: 1;
  }
}

// some of this below could potentailly be removed
// once the se-card component supports headings and footers

// this removes some extra space the font adds above and below the characters
$pl-card-heading-line-height: .825;

// this nudges the badge up to be vertically centered with the title font
$pl-card-heading-tag-margin-bottom: 5px;

%pl-card__heading,
.pl-card__heading-collapse {
  display: flex;
  flex-direction: column;
  @media (min-width: $desktop-width) {
    flex-direction: row;
  }
}

.pl-card__heading {
  @extend %pl-card__heading;
  padding-bottom: $layout-spacing-large;
}

.pl-card__heading-title {
  flex: 100 0 auto;
  padding-bottom: $layout-spacing-large;

  @media (min-width: $desktop-width) {
    padding-bottom: 0;
  }

  .pl-heading--large {
    [class*='pl-tag--'] {
      margin-bottom: $pl-card-heading-tag-margin-bottom;
      margin-left: $layout-spacing-medium;
    }
  }

  // direct child shown only-at phone
  > [class*='pl-tag--'] {
    margin: 12px 0 0;
  }
}

.pl-card__heading-info-description {
  font-size: $font-small;
  line-height: $pl-card-heading-line-height;
  padding-top: $layout-spacing-medium / 2;
}

.pl-card__heading-info-container {
  flex: 0 20 auto;
  @media (min-width: $desktop-width) {
    padding-top: ($font-large * .2);
  }
}

%pl-card__heading-info,
.pl-card__heading-info {
  padding: 0 $layout-spacing-medium;
}

.pl-card__heading-info--count {
  @extend %pl-card__heading-info;
  border-left: 4px solid $color-nbc-orange;

  [class*='pl-heading--'] {
    color: $color-nbc-orange;
    line-height: $pl-card-heading-line-height;
  }
}

.pl-card__heading-info--amount {
  @extend %pl-card__heading-info;
  border-left: 4px solid $color-nbc-green;

  [class*='pl-heading--'] {
    color: $color-nbc-green;
    line-height: $pl-card-heading-line-height;
  }
}

.pl-card__heading-info--blue {
  @extend %pl-card__heading-info;
  border-left: 4px solid $color-nbc-blue;

  [class*='pl-heading--'] {
    color: $color-nbc-blue;
    line-height: $pl-card-heading-line-height;
  }
}

.pl-card__footer {
  border-top: 1px solid $color-gray-10;
  padding-top: $layout-spacing-large;
  white-space: nowrap;

  .pl-link + .pl-link {
    margin-left: $layout-spacing-large;
  }
}
