.pl-notification-tile {
  background: white;
  border: 1px solid #8ad2ee;
  box-shadow: 0 1px 1px 0 #8ed2ed;
  color: #2e8cc3;
  margin: $layout-spacing-large 0;

  .pl-notification-tile__inner {
    padding: $layout-spacing-large $layout-spacing-medium;
  }

  .pl-notification-tile__icon {
    height: 28px;
    margin: 0 6px;
    width: 28px;
  }
}

.pl-notifications__panel--zero-state {
  color: #959ca0;
  padding-top: $layout-spacing-xlarge;
  text-align: center;

  .pl-notifications__icon {
    height: 50px;
    width: 50px;
  }
}
