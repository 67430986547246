ui-cropper {
  display: block;
  width: 100%;

  &.fixed-height {
    height: 100%;
    overflow: hidden;
    position: relative;

    canvas {
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
    }
  }

  &.full-width {
    text-align: center;

    canvas {
      display: inline;
    }
  }

  canvas {
    // Disable Outline
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    outline: none;
    // mobile webkit
  }

  .loading {
    -webkit-align-items: center;
    -webkit-justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .75);
    color: white;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    width: 100%;

    .fixed-height {
      height: 100%;
      position: absolute;
    }
  }
}
