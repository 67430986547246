// ## Search Field
$search-radius: 120px;

.pl-search-field {
  border-radius: $search-radius;
  display: block;
  margin: -1px;
  padding: 1px;
  position: relative;
}

.pl-search-field--condensed {
  border-radius: $search-radius;
  display: inline-block;
  position: relative;
}

.pl-search-field--filters {
  border-radius: $search-radius;
  display: block;
  position: relative;
  @media(min-width: $tablet-width) {
    display: inline-block;
  }
}

.pl-search-field__icon,
.pl-search-field__clear {
  @extend %pl-icon;
  position: absolute;
  right: 8px;
  top: 9px;
}

.pl-search-field__clear {
  cursor: pointer;
}

.pl-search-field__input {
  @extend %pl-field;
  border-radius: inherit;
  padding-left: 16px;
  padding-right: 30px;
  width: 100%;
}

.pl-search-field__button {
  @extend %button;
  @extend %button-synced-height;
  border-radius: 0 $search-radius $search-radius 0;
  white-space: nowrap;
}
