$claim-header-height: 40px;
$claim-footer-height: 52px;

.pl-claim-body {
  min-height: calc(100vh - #{$claim-header-height} - #{$claim-footer-height});
}

.pl-claim-footer {
  background-color: black;
  color: white;
  position: relative;
  text-align: right;
  width: 100%;
  z-index: 99;
}
