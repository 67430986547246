// ## Empty state
// An informational message when there is not content to display, often on list views.
%pl-empty-state {
  margin: auto;
  max-width: 25rem;
  padding: $layout-spacing-large $layout-spacing-medium;
  position: relative;
}

.pl-empty-state {
  @extend %pl-empty-state;
  text-align: center;
}

.pl-empty-state--left {
  @extend %pl-empty-state;

  .pl-empty-state__title,
  .pl-empty-state__message {
    text-align: center;
  }

  @include media-small {
    padding-left: calc(#{$font-xxxlarge} + #{$layout-spacing-large});

    .pl-empty-state__icon {
      left: 0;
      position: absolute;
      top: $layout-spacing-large;
    }

    .pl-empty-state__title,
    .pl-empty-state__message {
      text-align: left;
    }
  }
}

.pl-empty-state__icon {
  @extend %pl-icon;
  @include font-xxxlarge;
  color: $color-content-light;
  display: block;
  margin: .5rem auto 1.5rem;
}

.pl-empty-state__title {
  @include font-large;
  font-family: $font-heading;
  font-weight: $font-regular;
  margin: 0 0 $layout-spacing-large;
}

.pl-empty-state__message {
  color: $color-content-light;
  margin: 0 auto $layout-spacing-large;
  max-width: 400px;

  .pl-empty-state__title + & {
    margin-top: $layout-spacing-small - $layout-spacing-large;
  }
}

.pl-empty-state__button {
  @extend %button;
  display: block;
  margin: $layout-spacing-small 0;
}

$steps-header-icon-size: 54px;
$steps-header-icon-offset: $steps-header-icon-size + $layout-spacing-medium;
$steps-tooltip-icon-size: 20px;
$steps-step-icon-size: 22px;
$steps-step-icon-background-size: $steps-step-icon-size - 8;
$steps-step-icon-offset: $steps-step-icon-size + $layout-spacing-medium;
$steps-status-icon-size: 16px;
$steps-status-icon-offset: $steps-status-icon-size + $layout-spacing-small;

.pl-empty-state-steps {
  padding-left: $steps-header-icon-offset;
}

.pl-empty-state-steps__header {
  margin-bottom: $layout-spacing-xlarge;
  position: relative;
}

.pl-empty-state-steps__header-icon {
  color: $color-content-light;
  fill: currentColor;
  height: $steps-header-icon-size;
  left: -$steps-header-icon-offset;
  position: absolute;
  width: $steps-header-icon-size;
}

.pl-empty-state-steps__header-title {
  @extend %heading;
  @include font-xlarge;
}

.pl-empty-state-steps__header-subtitle {
  @extend %heading;
  @include font-large;
  color: $color-content-light;
  font-size: $font-small;
  margin-top: 0;
}

.pl-empty-state-steps__header-tooltip {
  color: $color-content-xlight;
  cursor: pointer;
  display: inline;
  fill: currentColor;
  height: $steps-tooltip-icon-size;
  vertical-align: top;
  width: $steps-tooltip-icon-size;

  &:hover {
    color: $color-content-light;
  }
}

%pl-empty-state-steps__step-icon {
  border-radius: 50%;
  color: #fff;
  height: $steps-step-icon-size;
  line-height: $steps-step-icon-size;
  margin-left: -($steps-step-icon-offset);
  position: absolute;
  text-align: center;
  width: $steps-step-icon-size;
}

%pl-empty-state-steps__step {
  margin-bottom: $layout-spacing-large;
  padding-left: $steps-step-icon-offset;
}

.pl-empty-state-steps__step {
  @extend %pl-empty-state-steps__step;
  color: $color-content-light;

  .pl-empty-state-steps__step-icon {
    @extend %pl-empty-state-steps__step-icon;
    background-color: $color-gray-20;
  }
}

.pl-empty-state-steps__step--active {
  @extend %pl-empty-state-steps__step;

  .pl-empty-state-steps__step-icon {
    @extend %pl-empty-state-steps__step-icon;
    background-color: $color-highlight;
  }
}

.pl-empty-state-steps__step--complete {
  @extend %pl-empty-state-steps__step;

  .pl-empty-state-steps__step-icon {
    @extend %pl-empty-state-steps__step-icon;
    background-color: $color-success;
    background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16px" height="12px" viewBox="0 0 16 12" enable-background="new 0 0 16 12"><polygon fill="#fff" points="13.891,0 4.48,9.148 1.414,6.082 0,7.496 3.763,11.26 4.46,11.957 5.167,11.27 15.285,1.434 "/></svg>');
    background-position: center;
    background-repeat: no-repeat;
    background-size: $steps-step-icon-background-size;
    text-indent: -9999px;
  }
}

.pl-empty-state-steps__step-title {
  font-size: $font-medium;
  line-height: $steps-step-icon-size; // keep text vertically centered with icon
  margin-bottom: $layout-spacing-xsmall;
}

.pl-empty-state-steps__step-text {
  @extend %content-spacing;
  font-size: $font-xsmall;
}

.pl-empty-state-steps__tag--alert {
  @extend %pl-tag--alert;
}

.pl-empty-state-steps__tag--highlight {
  @extend %pl-tag--highlight;
}

.pl-empty-state-steps__tag--success {
  @extend %pl-tag--success;
}

.pl-empty-state-steps__tag--warn {
  @extend %pl-tag--warn;
}

.pl-empty-state-steps__tag--alert,
.pl-empty-state-steps__tag--highlight,
.pl-empty-state-steps__tag--success,
.pl-empty-state-steps__tag--warn {
  vertical-align: middle;
}

.pl-empty-state-steps-statuses {
  margin-top: $layout-spacing-large;

  > :not(:last-child) {
    margin-bottom: $layout-spacing-medium;
  }
}

.pl-empty-state-steps-status {
  padding-left: $steps-status-icon-offset;
  position: relative;
}

%pl-empty-state-steps-status__icon {
  fill: currentColor;
  height: $steps-status-icon-size;
  left: 0;
  position: absolute;
  width: $steps-status-icon-size;
}

.pl-empty-state-steps-status__icon--alert {
  @extend %pl-empty-state-steps-status__icon;
  color: $color-alert;
}

.pl-empty-state-steps-status__icon--highlight {
  @extend %pl-empty-state-steps-status__icon;
  color: $color-highlight;
}

.pl-empty-state-steps-status__icon--success {
  @extend %pl-empty-state-steps-status__icon;
  color: $color-success;
}

.pl-empty-state-steps-status__icon--warn {
  @extend %pl-empty-state-steps-status__icon;
  color: $color-warn;
}
