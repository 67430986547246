// jquery.minicolors.css version 2.1.7

.minicolors {
  position: relative;
}

.minicolors-sprite {
  background-image: url('#{$shared-image-path}/jquery.minicolors.png');
}

.minicolors-swatch {
  background-position: -80px 0;
  border: solid 1px #ccc;
  cursor: text;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: absolute;
  vertical-align: middle;
}

.minicolors-swatch-color {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.minicolors input[type='hidden'] + .minicolors-swatch {
  cursor: pointer;
  position: static;
  width: 28px;
}

.minicolors-panel {
  background: white;
  border: solid 1px #ccc;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  box-sizing: content-box;
  display: none;
  height: 152px;
  position: absolute;
  width: 173px;
  z-index: 99999;
}

.minicolors-panel.minicolors-visible {
  display: block;
}

// Panel positioning
.minicolors-position-top .minicolors-panel {
  top: -154px;
}

.minicolors-position-right .minicolors-panel {
  right: 0;
}

.minicolors-position-bottom .minicolors-panel {
  top: auto;
}

.minicolors-position-left .minicolors-panel {
  left: 0;
}

.minicolors-with-opacity .minicolors-panel {
  width: 194px;
}

.minicolors .minicolors-grid {
  background-position: -120px 0;
  cursor: crosshair;
  height: 150px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 150px;
}

.minicolors .minicolors-grid-inner {
  height: 150px;
  left: 0;
  position: absolute;
  top: 0;
  width: 150px;
}

.minicolors-slider-saturation .minicolors-grid {
  background-position: -420px 0;
}

.minicolors-slider-saturation .minicolors-grid-inner {
  background-image: inherit;
  background-position: -270px 0;
}

.minicolors-slider-brightness .minicolors-grid {
  background-position: -570px 0;
}

.minicolors-slider-brightness .minicolors-grid-inner {
  background-color: black;
}

.minicolors-slider-wheel .minicolors-grid {
  background-position: -720px 0;
}

.minicolors-slider,
.minicolors-opacity-slider {
  background-color: white;
  background-position: 0 0;
  cursor: row-resize;
  height: 150px;
  left: 152px;
  position: absolute;
  top: 1px;
  width: 20px;
}

.minicolors-slider-saturation .minicolors-slider {
  background-position: -60px 0;
}

.minicolors-slider-brightness .minicolors-slider {
  background-position: -20px 0;
}

.minicolors-slider-wheel .minicolors-slider {
  background-position: -20px 0;
}

.minicolors-opacity-slider {
  background-position: -40px 0;
  display: none;
  left: 173px;
}

.minicolors-with-opacity .minicolors-opacity-slider {
  display: block;
}

// Pickers
.minicolors-grid .minicolors-picker {
  background: none;
  border: solid 1px black;
  border-radius: 10px;
  height: 12px;
  left: 70px;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 70px;
  width: 12px;
}

.minicolors-grid .minicolors-picker > div {
  border: solid 2px white;
  border-radius: 8px;
  box-sizing: content-box;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
}

.minicolors-picker {
  background: white;
  border: solid 1px black;
  box-sizing: content-box;
  height: 2px;
  left: 0;
  margin-top: -2px;
  position: absolute;
  top: 0;
  width: 18px;
}

// Inline controls
.minicolors-inline {
  display: inline-block;
}

.minicolors-inline .minicolors-input {
  display: none !important;
}

.minicolors-inline .minicolors-panel {
  box-shadow: none;
  display: inline-block;
  left: auto;
  position: relative;
  top: auto;
  z-index: auto;
}

// Default theme
.minicolors-theme-default .minicolors-swatch {
  height: 18px;
  left: 5px;
  top: 5px;
  width: 18px;
}
.minicolors-theme-default.minicolors-position-right .minicolors-swatch {
  left: auto;
  right: 5px;
}
.minicolors-theme-default.minicolors {
  display: inline-block;
  width: auto;
}
.minicolors-theme-default .minicolors-input {
  display: inline-block;
  height: 20px;
  padding-left: 26px;
  width: auto;
}
.minicolors-theme-default.minicolors-position-right .minicolors-input {
  padding-left: inherit;
  padding-right: 26px;
}

// Bootstrap theme
.minicolors-theme-bootstrap .minicolors-swatch {
  border-radius: 3px;
  height: 24px;
  left: 3px;
  top: 3px;
  width: 24px;
}
.minicolors-theme-bootstrap .minicolors-swatch-color {
  border-radius: inherit;
}
.minicolors-theme-bootstrap.minicolors-position-right .minicolors-swatch {
  left: auto;
  right: 3px;
}
.minicolors-theme-bootstrap .minicolors-input {
  padding-left: 44px;
}
.minicolors-theme-bootstrap.minicolors-position-right .minicolors-input {
  padding-left: 12px;
  padding-right: 44px;
}
.minicolors-theme-bootstrap .minicolors-input.input-lg + .minicolors-swatch {
  border-radius: 5px;
  height: 37px;
  left: 4px;
  top: 4px;
  width: 37px;
}
.minicolors-theme-bootstrap .minicolors-input.input-sm + .minicolors-swatch {
  height: 24px;
  width: 24px;
}
