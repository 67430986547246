$progressbar-height: 30px;

.pl-stacked-progress__bar {
  background-color: $color-gray-10;
  border-radius: 100px;
  height: $progressbar-height;
  overflow: hidden;
  width: 100%;
}

@each $label, $color in (success: $color-success, warn: $color-warn, alert: $color-alert) {
  .pl-stacked-progress__bar--#{$label} {
    background-color: $color;
    float: left;
    height: $progressbar-height;
    transition: width .5s;
  }
}

.pl-stacked-progress__number--container {
  border-right: 1px solid $color-gray-20;
  padding: 0 $layout-spacing-large;
  text-align: center;
}

.pl-stacked-progress__number--count {
  color: $color-success;
  font-family: $font-default;
  font-size: $font-xxxlarge;
  font-weight: $font-semibold;
  margin-bottom: 0;
}

.pl-stacked-progress__number--label {
  font-family: $font-default;
  font-size: $font-large;
}

%pl-stacked-progress__key,
.pl-stacked-progress__key,
.pl-stacked-progress__key--empty {
  @include font-xsmall
  color: $color-content-light;
  font-family: $font-heading;
  font-weight: $font-regular;
  padding-right: $layout-spacing-large;

  &::before {
    background-color: $color-gray-10;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 1em;
    margin-right: .1em;
    vertical-align: -.12em;
    width: 1em;
  }
}

@each $label, $color in (success: $color-success, warn: $color-warn, alert: $color-alert) {
  .pl-stacked-progress__key--#{$label} {
    @extend %pl-stacked-progress__key;

    &::before {
      background-color: $color;
    }
  }
}

.pl-stacked-progress__buttons {
  padding-bottom: $layout-spacing-medium;
  text-align: right;

  button {
    margin-left: $layout-spacing-small;
  }
}
