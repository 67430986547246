// # Detail list

.pl-detail-list {
  list-style: none;

  & + & {
    margin-top: 1.4286rem; // 20px / 14px
  }
}
.pl-detail-list__pair,
%pl-detail-list__pair {
  margin-top: .5714rem; // 8px / 14px
}
.pl-detail-list__pair--emphasis {
  @extend %pl-detail-list__pair;
}
.pl-detail-list__pair--total {
  @extend %pl-detail-list__pair;
}
.pl-detail-list__key,
.pl-detail-list__value {
  display: inline-block;
}
.pl-detail-list__key {
  color: $color-content-light;
  float: left;
  font-size: $font-small;
  font-weight: $font-regular;
  margin-right: .5714rem; // 8px / 14px
  text-align: right;
  width: calc(50% - .5714rem); // 8px / 14px

  .pl-detail-list__pair--emphasis & {
    font-weight: $font-semibold;
  }
  .pl-detail-list__pair--total & {
    border-top: solid 1px transparent;
    padding-top: .2857rem; // 4px / 14px
  }
}
.pl-detail-list__value {
  margin-left: .5714rem; // 8px / 14px
  text-align: left;

  .pl-detail-list__pair--emphasis & {
    font-weight: $font-semibold;
  }
  .pl-detail-list__pair--total & {
    border-top: solid 1px $color-content-xlight;
    padding-right: 1.1428rem; // 16px / 14px
    padding-top: .2857rem; // 4px / 14px
  }
}
