// # Tabs
// Horizontal tabs for navigating within a page
$pl-tabs-link-focus-outline: mix($color-highlight, white, 28);
$pl-tabs-link-focus-bg: mix($color-highlight, white, 4);

.pl-tabs {
    display: flex;
    overflow-x: auto;
}

.pl-tabs__item {
    white-space: nowrap;
    &+& {
        margin-left: 1.7143em; // 24px / 14px
    }
    &:first-child {
        margin-left: 0.143em; // 2px / 14 px
    }
}

.pl-tabs__link {
    border-bottom: solid 2px transparent;
    color: $color-content;
    cursor: pointer;
    display: block;
    padding: 0.429em; // 6px / 14px
    text-decoration: none;
    &:hover {
      border-bottom-color: $color-content-xlight;
    }
    &:focus {
      outline: 0;
    }
    &:focus-visible {
      background-color: $pl-tabs-link-focus-bg;
      padding: 0.286em 0.429em; // 4px / 14px // 6px / 14px
      margin: 0.143em 0; // 2px / 14px
      border-radius: 4px;
      outline-offset: 0px;
      outline: 2px solid $pl-tabs-link-focus-outline;
      border-bottom-color: $pl-tabs-link-focus-bg;
    }
    &[aria-selected='true'],
    &[aria-current='true'] {
        border-bottom-color: $color-highlight;
        &:focus-visible {
            border-bottom-color: $pl-tabs-link-focus-bg;
            &:after {
                content: '';
                display: block;
                width: calc(100% + 4px);
                height: 1.5px;
                margin: 0px 0px -0.143em -0.107em; // -2px / 14px // -1.5px / 14px
                background-color: $color-highlight;
            }
            &:hover {
                border-bottom-color: $pl-tabs-link-focus-bg;
            }
        }
        &:hover {
            border-bottom-color: $color-highlight;
        }
    }
}

// For `jquery-accessible-tabpanel-aria` plugin
.js-tabcontent {
    &[aria-hidden='true'] {
        display: none;
    }
    .invisible {
        left: -9999px;
        position: absolute;
    }
}
