.pl-image-crop {
  display: block;
  max-height: 90vh;
  min-height: 100px;
  overflow: hidden;
  position: relative;
}

.pl-image-crop__aspect-ratio {
  display: block;
  opacity: 0;
}

.pl-image-crop__cropper {
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  canvas {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); // Disable Outline (mobile webkit)
    display: block;
    left: 50%;
    outline:  none;
    position: absolute;
    top: 50%;
  }
}
