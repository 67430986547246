.pl-form-result {
  overflow: hidden;

  @media (min-width: $tablet-width) {
    margin: 0 $layout-spacing-large;
  }
}

.pl-form-result__metadata {
  .pl-button--minimal {
    max-width: 95vw;
    text-align: right;
  }
}

.pl-form-result__questions {
  column-gap: $layout-spacing-large;
  column-width: 25rem; // 350px @ 14px
  margin-top: -1px;
  transform: scale(1); // fix disappearing content in Safari

  .pl-field__label,
  .pl-field__label--required {
    font-size: $font-xsmall;
  }

  // Prevents Chrome from cramming all items into the first column
  // when the last item is larger than all the rest combined.
  &::after {
    content: '';
    display: block;
    height: 1px;
    margin-bottom: -1px;
  }
}

.pl-question {
  @include pl-column-break-none;
  border-top: 1px solid $gray-light;
  display: block;
  margin: 0 !important;
  padding: $layout-spacing-medium $layout-spacing-large * 2 $layout-spacing-medium $layout-spacing-large;
  position: relative;
  width: 100%;

  textarea {
    resize: none;
  }

  .pl-edit-mode & {
    border-top-color: transparent; // preserve 1px width for layout
  }

  &.pl-is-editable {
    cursor: pointer;
  }
}

%pl-question__icon {
  color: $gray-light;
  padding: 0;
  position: absolute;
  right: $layout-spacing-medium;
  top: $layout-spacing-medium;
}

.pl-question__lock {
  @extend %pl-question__icon;
}

.pl-question__edit {
  @extend %pl-question__icon;
  &:hover {
    color: $color-content-light;
  }
}

.pl-question__answer {
  @include font-small;
  padding: calc(#{$input-vertical-padding} + 1px) 0;
  white-space: pre-line;
  word-wrap: break-word;
}

.pl-question__answer--nowrap {
  @include font-small;
  padding: calc(#{$input-vertical-padding} + 1px) 0;
  white-space: nowrap;
}

.pl-question__answer--color {
  .pl-link   {
    display: inline-block;
    padding-left: 1em;
    vertical-align: middle;
  }
}

.pl-question__answer--color-swatch {
  border: 1px solid $gray-light;
  display: block;
  float: left;
  padding: .75em 2em;
}

.pl-question__sub-field {
  margin-top: 1em;
}


.pl-profile-information-pane {
  background: #fff;
  box-shadow: 0 1px 2px #ccc;
  margin: $layout-spacing-large 0;

  .pl-pane__header {
    border-bottom: 2px solid $color-highlight;

    h2 {
      margin: 0;
      padding: 0 $layout-spacing-large;
    }

    .pl-form__edit {
      color: $gray-light;
      padding: 0 $layout-spacing-medium;
    }
  }

  .pl-form-result {
    margin: 0;
    padding: 0 $layout-spacing-medium;

    .pl-question {
      padding: $layout-spacing-medium 0 7px $layout-spacing-medium;

      .pl-field__label,
      .pl-field__label--required {
        @include font-xsmall;
        padding-bottom: 0;
      }

      .pl-question__answer {
        @include font-medium;
      }
    }

    .pl-question--border-bottom {
      border-bottom: 1px solid $gray-light;
    }
  }

  .pl-edit-mode {

    .pl-column-break-none > .pl-question:last-child {
      padding-bottom: $layout-spacing-medium;
    }

    .pl-question--border-bottom {
      border-bottom-color: transparent; // preserve 1px width for layout
    }
  }
}


// Spacing modifications for when this appears inside a container card (until se-ui forms are ready)
se-container-card {
  $se-container-card-spacing: 1.5rem;

  .pl-form-result {
    margin: 0 -1.5rem -1.5rem;
    overflow: hidden;
    padding: 0 .75rem;

    // @media (min-width: $tablet-width) {
    //   padding: 0 .75rem .75rem;
    // }
  }

  // This class must be added to overcome the padding of the container card
  .pl-form-actions__wrapper {
    margin: 0 -1.5rem;
    position: relative;

    &:not(.pl-sticky--bottom-on) {
      top: 1.5rem;
    }
  }

  .pl-form-actions--condensed {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

}
