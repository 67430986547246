// # Buttons
// Button elements, submit inputs, and button-styled links

// # Default

@import 'buttons-silent';

.pl-button,
.pl-button--small {
  @extend %pl-button;
}

.pl-button--destroy {
  @extend %pl-button--destroy;
}

.pl-button--primary,
.pl-button--highlight {
  @extend %pl-button--highlight;
}

.pl-button--large {
  @extend %pl-button--large;
}

.pl-button--full {
  @extend %pl-button--full;
}

.pl-button--minimal {
  @extend %pl-button--minimal;

  &--highlight {
    @extend %pl-button--minimal;
    color: $color-highlight;
    &:hover:not(:disabled):not(.pl-is-disabled) {
      color: $color-highlight-dark;
    }
  }

  &-link {
    @extend %pl-button--minimal;
    color: $color-highlight;
    cursor: pointer;
    font-weight: $font-regular;

    &:hover:not(:disabled):not(.pl-is-disabled),
    &:focus {
      color: $color-highlight;
      text-decoration: underline;
    }
  }

  &-light {
    @extend %pl-button--minimal;
    font-weight: $button-minimal-light-font-weight;
  }
}

// Force remove shadow in button groups to stay consistent with CL patterns of flat buttons in a group
action-buttons-buttons > * {
  box-shadow: none !important;
}

%button-unstyled-default {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
}

.pl-help-action {
  @extend %button-unstyled-default;
  border-radius: 2px;
  color: $color-gray-50;
  padding: $layout-spacing-xsmall;
  margin-right: 8px;
  &:hover {
    background-color: $color-gray-10;
  }
  &:focus {
    background-color: $color-gray-15;
    outline: 0;
  }
}
