.pl-image-upload {
  border: 1px dashed $color-gray-15;
  border-radius: 2px;
  color: $color-gray-50;
  position: relative;
  width: 100%;

  &--circular,
  &--circular &__aspect-ratio,
  &--circular &__content {
    border-radius: 50%;
  }

  .pl-drag-in-progress & {
    border-color: $color-highlight;
  }

  &.dragover {
    background: $color-gray-05;
  }

  &.pl-has-error {
    border-color: $color-alert;
  }

  .show-on-drag {
    display: none;

    .pl-drag-in-progress & {
      display: block;
    }
  }

  .hide-on-drag {
    .pl-drag-in-progress & {
      display: none;
    }
  }

  &__aspect-ratio {
    height: 0;
  }

  &__content {
    align-items: center;
    bottom: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;

    // font and color set here are to hide text at phone size
    @media (max-width: $media-small - 1) {
      color: transparent;
      font-size: 1px;
    }

    @media (min-width: $media-small) {
      @include font-small;
    }
  }

  &__button {
    display: block;
    margin: $layout-spacing-small auto $layout-spacing-xsmall;
  }

  &--circular &__button {
    text-transform: uppercase;
  }

  &__cropper {
    height: 400px;
  }

  &__image {
    background-position: center;
    background-size: cover;
    border: 1px solid $color-gray-15;
    border-radius: 2px;
    display: block;

    &--circular {
      border-radius: 50%;
    }
  }

  &__upload-text {
    max-width: 200px;
  }

  &__add-text {
    margin-bottom: $layout-spacing-xsmall;
  }

  // used by the pl-image-field to align internal icon
  &__drop {
    padding-bottom: 10%;
  }

  &__drop-text {
    margin-top: $layout-spacing-xsmall;
  }

}
