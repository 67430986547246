$pl-wizard-max-content-width: 1024px;
$pl-wizard-icon-size: 60px;
$pl-wizard-main-col-width: 60vw;
$pl-wizard-panel-width: 450px;
// Below are calculated vars based on other components
$pl-wizard-content-padding: calc(50% - #{$pl-wizard-max-content-width / 2} + #{$layout-spacing-large});
$pl-wizard-footer-rem: $button-line-height + $button-padding-vertical * 2;
$pl-wizard-footer-px: $pl-form-actions-spacing--condensed * 4 + $form-actions-button-spacing * 2 + 3px; // 2px for button border 1px for footer border
$pl-wizard-footer-height: calc(#{$pl-wizard-footer-rem} + #{$pl-wizard-footer-px});
$pl-wizard-footer-height-negative: calc(#{-$pl-wizard-footer-rem} - #{$pl-wizard-footer-px});

.pl-wizard {
  #{'-webkit-overflow-scrolling'}: touch;
  background: $gray-xlight;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-overlay;

  > * {
    flex: 0 0 auto;
  }

  .pl-field__container {
    &:first-child {
      margin-top: 0;
    }
  }
}

%pl-wizard__header {
  align-items: center;
  background: $color-highlight-light;
  border-bottom: 1px solid $gray-light;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.pl-wizard__header {
  @extend %pl-wizard__header;
}

.pl-wizard__header--success {
  @extend %pl-wizard__header;

  @include media-small {
    background: $color-success-light;
  }
}

.pl-wizard__icon {
  background: $bg-default;
  border-right: 1px solid $gray-light;
  display: none;
  flex: 0 0 auto;
  padding: $layout-spacing-small;

  > * {
    display: block; // fix line-height issues
    height: $pl-wizard-icon-size;
  }
}

.pl-wizard__title {
  @extend %heading;
  @include font-xsmall;
  flex: 1 1 100%;
  font-weight: bold;
  margin: 0;
  padding: $layout-spacing-medium;
  text-align: center;
}

.pl-wizard__skip-link {
  color: $color-content-lighter;
  cursor: pointer;
  display: block;
  margin-top: 1em;
  text-align: center;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.pl-wizard__se-logo {
  display: block;
  margin: auto;
  max-width: 70%;
  padding: $layout-spacing-medium 0 $layout-spacing-large;
  width: 250px;
}

.pl-wizard__intro {
  color: $color-content;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;
}

.pl-wizard__intro--small {
  display: block;
  font-size: $font-small;
  line-height: 1em;
}

.pl-wizard__scroll {
  flex: 1 1 100%;
  overflow: auto;
  position: relative;
}

.pl-wizard__panel {
  background: $bg-default;
  padding: $layout-spacing-large $layout-spacing-medium;

  // Force white to fill phone if there is no tip
  &:only-child {
    min-height: 100%;
  }

  hr {
    margin-left: $layout-spacing-medium * -1;
    margin-right: $layout-spacing-medium * -1;
  }
}

.pl-wizard__panel-header {
  @include font-medium;
  margin: (-$layout-spacing-large) (-$layout-spacing-medium) $layout-spacing-large;
  padding: $layout-spacing-large $layout-spacing-medium 0;
}

.pl-wizard__content {
  background: $bg-default;
  padding: $layout-spacing-large $layout-spacing-medium;

  // allow preview content to take up full area
  &.pl-wizard__preview,
  &.pl-wizard__preview--full {
    padding: 0 !important;
  }

  // Force white to fill phone if there is no tip
  &:only-child {
    min-height: 100%;
  }
}

.pl-wizard__tip {
  background: $gray-xlight;
  color: $color-content-light;
  padding: $layout-spacing-large $layout-spacing-medium;
}

%pl-wizard__preview {
  align-items: stretch;
  bottom: 0;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;

  iframe {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.pl-wizard__preview,
.pl-wizard__preview--full {
  @extend %pl-wizard__preview;
}

%pl-wizard__footer {
  background: $bg-default;
  flex: 0 0 auto;
  position: relative;
  z-index: 1;
}

.pl-wizard__footer {
  @extend %pl-wizard__footer;
}

.pl-wizard__footer--full {
  @extend %pl-wizard__footer;
}

.pl-wizard__inline-footer {
  @include font-xxsmall;
  text-align: center;

  @include media-small {
    @include font-small;
  }
}


// BOOKEND SCREENS COMPONENTS

.pl-wizard__bookend {
  background-color: $bg-default;
  padding: $layout-spacing-large $layout-spacing-medium;
}

.pl-wizard__bookend-button {
  @extend %button;
  display: block;
  margin: $layout-spacing-small 0;
  width: 100%;
}


// TABLET STYLES

@media (min-width: $media-tablet) {

  .pl-wizard__icon {
    display: block;
  }

  .pl-wizard__title {
    @include font-medium;
    text-align: left;
    text-transform: none;
  }

  .pl-wizard__scroll {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .pl-wizard__panel {
    border: 1px solid $gray-light;
    margin: ($layout-spacing-large * 2) auto;
    padding-left: $layout-spacing-large * 3;
    padding-right: $layout-spacing-large * 3;
    padding-top: $layout-spacing-large * 2;
    width: $pl-wizard-panel-width;

    // reset small screen style
    &:only-child {
      min-height: 0;
    }

    hr {
      margin-left: $layout-spacing-large * -3;
      margin-right: $layout-spacing-large * -3;
    }

    .pl-wizard__inline-footer {
      background: $gray-xlight;
      border-top: 1px solid $gray-light;
      margin: $layout-spacing-large calc(#{$layout-spacing-large * -3} - 1px) calc(#{$layout-spacing-large * -1} - 1px);

      hr {
        display: none;
      }
    }
  }

  .pl-wizard__panel-header {
    border-bottom: 1px solid $gray-light;
    margin: ($layout-spacing-large * -2) ($layout-spacing-large * -3) ($layout-spacing-large * 1.5);
    padding: $layout-spacing-large ($layout-spacing-large * 2);
  }

  .pl-wizard__content {
    border-bottom: $pl-wizard-footer-height solid transparent;
    bottom: 0;
    overflow: auto;
    padding: ($layout-spacing-large * 2.5) $layout-spacing-large;
    position: absolute;
    top: 0;
    width: $pl-wizard-main-col-width;
  }

  .pl-wizard__tip {
    bottom: 0;
    height: 100%;
    left: $pl-wizard-main-col-width;
    overflow: auto;
    padding: ($layout-spacing-large * 2.5) $layout-spacing-large;
    position: absolute;
    top: 0;
    width: calc(100% - #{$pl-wizard-main-col-width}); // accounts for scrollbar if present
  }

  .pl-wizard__preview {
    border-bottom: $pl-wizard-footer-height solid transparent;
  }

  .pl-wizard__footer {
    margin-top: $pl-wizard-footer-height-negative;
    width: $pl-wizard-main-col-width;
  }

  .pl-wizard__bookend {
    padding: $layout-spacing-large * 2.5 $layout-spacing-large;
  }

}

// ENFORCE MAX CONTENT WIDTH

@media (min-width: $pl-wizard-max-content-width) {

  .pl-wizard__content {
    padding: ($layout-spacing-large * 2.5) $layout-spacing-large ($layout-spacing-large * 2.5) $pl-wizard-content-padding;
  }

  .pl-wizard__tip {
    padding: ($layout-spacing-large * 2.5) $pl-wizard-content-padding ($layout-spacing-large * 2.5) $layout-spacing-large;
  }

}
