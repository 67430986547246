.pl-message-preview {
  color: $gray;
  display: flex;
  margin-top: $layout-spacing-large;

  > * {
    flex-grow: 1;
  }
}

.pl-message-preview__icon-container {
  flex: 0 1 auto;
}

.pl-message-preview__icon {
  fill: $gray-medium;
  margin-right: $layout-spacing-large;
  margin-top: 4px; // line it up with the top of the text
  width: 48px;
}

.pl-message-preview__icon--text {
  @extend .pl-message-preview__icon;
  height: 55px;
}

.pl-message-preview__icon--email {
  @extend .pl-message-preview__icon;
  height: 34px;
}

.pl-message-preview__header {
  @extend %pl-heading--medium;
  border-bottom: 2px solid $gray;
  font-weight: $font-semibold;

  .pl-message-preview__character-count {
    float: right;
  }
}

.pl-message-preview__content {
  padding-top: $layout-spacing-medium;

  hr {
    margin: $layout-spacing-medium 0;
  }
}
