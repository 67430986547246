$pl-avatar-size: 70px;
$pl-avatar-size--xsmall: 32px;
$pl-avatar-size--small: 64px;
$pl-avatar-size--medium: 75px;
$pl-avatar-size--large: 100px;
$pl-avatar-size--xlarge: 144px;
$pl-avatar-img-height: 300px;

%pl-avatar {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  height: 1em;
  line-height: 1;
  overflow: hidden;
  position: relative;
  width: 1em;
}

.pl-avatar {
  @extend %pl-avatar;
  font-size: $pl-avatar-size;
}

.pl-avatar--xsmall {
  @extend %pl-avatar;
  font-size: $pl-avatar-size--xsmall;
}

.pl-avatar--small {
  @extend %pl-avatar;
  font-size: $pl-avatar-size--small;
}

.pl-avatar--medium {
  @extend %pl-avatar;
  font-size: $pl-avatar-size--medium;
}

.pl-avatar--large {
  @extend %pl-avatar;
  font-size: $pl-avatar-size--large;
}

.pl-avatar--xlarge {
  @extend %pl-avatar;
  font-size: $pl-avatar-size--xlarge;
}

.pl-avatar--edit {
  @extend %pl-avatar;
  display: block;
  font-size: $pl-avatar-img-height;
  margin: auto;
}

%pl-avatar__initial {
  color: white;
  font-size: 24%;
  height: 1em;
  left: 50%;
  margin: -.5em;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 1em;
}

.pl-avatar__initial {
  @extend %pl-avatar__initial;
}

.pl-org-avatar {
  &__logo {
    align-items: center;
    display: flex;
  }

  &__org-name {
    margin-left: $layout-spacing-normal;
  }
}
