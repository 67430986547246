// sass-lint:disable no-ids
@media screen {
  #Printable {
    left: -9999in;
    max-height: 100vh;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 8.5in;
  }
}
@media print {
  @page {
    size: letter portrait;
  }

  * {
    -webkit-print-color-adjust: exact; // print colors on table headers, etc.
  }

  body {
    margin: 0;
    padding: 0;
  }

  #Printable:not(:empty) {
    display: block;

    + #App {
      display: none;
    }
  }

  #ngin-bar,
  .pl-side-nav.pl-side-nav.pl-side-nav.pl-side-nav, // TODO: better way to override specificity?
  .sn-sticky-header,
  .sn-sticky-column {
    display: none !important;
  }

  html,
  body,
  #App,
  [sticky-container],
  %pl-pane {
    flex: 0 1 auto !important;
    height: auto !important;
    overflow: visible !important;
  }

  %pl-pane__container,
  .pl-pane__inner-scroller {
    display: block !important;
    height: auto !important;
    position: static !important;
    width: 100% !important;
  }
}
// sass-lint:enable no-ids
