/*
 This is where we put custom overrides of the default styles for the quill component
 */
.ql-editor a {
  color: #267ad4;

  &:active {
    color: #2b5ab8;
    text-decoration: underline;
  }
  &:hover {
    color: #2b5ab8;
    text-decoration: underline;
  }
  &:focus {
    color: #2b5ab8;
    text-decoration: underline;
  }
  &:visited {
    color: #267ad4
  }
}

.ql-editor u {
  text-decoration: underline;
}

%ql-frame,
.ql-frame {
  display: block;
  padding-bottom: 4px;

  .ql-editor {
    &:focus {
      border-color: $field-focus-border-color;
      box-shadow: $field-focus-box-shadow;
      outline: none;
      z-index: $z-grouped-field--focus;
    }
  }

  &.pl-has-error {
    .ql-editor {
      border-color: $color-alert;
      border-style: solid;
      border-width: 1px;

      &:focus {
        box-shadow: 0 0 0 1px $color-alert;
      }
    }
  }
}

.ql-frame--padded {
  @extend %ql-frame;
  padding-bottom: 4px;
}

/*
 Overrides of the default styles for the quill tooltip dialog
 */
.ql-snow {
  .ql-tooltip.ql-snow-tooltip {
    border: 0;
    box-shadow: none;

    &[data-mode=link]::before,
    &[data-mode=formula]::before,
    &[data-mode=video]::before {
      content: "";
    }

    &::before {
      content: "";
      margin: 0;
    }

    a.ql-preview {
      max-width: 110px;
    }
  }

  .ql-tooltip.ql-editing {

    a.ql-action {

      &::after {
        content: "Update URL";
        background-color: $color-highlight;
        border: 1px solid #127094;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        color: white;
        display: inline-block;
        height: 26px;
        padding: 0 15px;
        margin: 0;
      }
    }
  }

  .ql-editor {
    min-height: 100px;
    max-height: 225px;
  }
}
