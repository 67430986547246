.pl-info-highlight-label {
  @include font-small;
  color: $color-content-light;
  display: block;
}

.pl-info-highlight-data {
  @include font-xxlarge;
  font-weight: $font-semibold;
}

.pl-info-highlight-data--small {
  @include font-xlarge;
  font-weight: $font-regular;
}
