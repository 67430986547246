.pl-obo-membership {
  &-wizard-container {
    margin-left: $layout-spacing-normal;
    margin-right: $layout-spacing-normal;
  }

  &-wizard__heading--small {
    font-size: $font-small;
    font-weight: $font-bold;

    .pl-org-avatar__logo & {
      margin-left: $layout-spacing-normal;
    }
  }

  &-wizard__heading--medium {
    font-size: $font-medium;
    font-weight: $font-bold;
  }

  &-wizard-left-col {
    margin: 0 0 $layout-spacing-large $layout-spacing-normal + $layout-spacing-xsmall;
    text-align: left;
  }

  &-wizard-right-col {
    margin: 0 $layout-spacing-normal + $layout-spacing-xsmall $layout-spacing-large $layout-spacing-large;
    text-align: left;

    &__body {
      max-height: 200px;
      overflow: hidden;
      white-space: pre-line;
    }
  }

  &-list {
    li {
      font-weight: $font-semibold;
      padding: $layout-spacing-normal;
      + li {
        border-top: 1px solid $gray-light;
      }
    }
  }

  &-order-summary {
    display: flex;

    &__item-price {
      flex: 1 0 auto;
    }

    &__total-price {
      flex: 1 0 auto;
      text-align: right;
    }

    &__quantity {
      margin-right: $layout-spacing-normal;
    }
  }

}
