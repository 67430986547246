$feature-tag-app-spacing: 12px !default;

%pl-tag {
  border-radius: 2px;
  display: inline-block;
  font-size: $font-xxsmall;
  font-weight: $font-regular;
  line-height: $font-leading-small;
  padding: 2px 8px;
  text-transform: uppercase;
  vertical-align: middle;
}

// The `.pl-feature-tag` classes are deprecated and `.pl-tag` classes should be
// used instead. The `.pl-feature-tag` aliases remain here for backward compatibility

%pl-tag--alert {
  @extend %pl-tag;
  background: $color-alert;
  color: $color-content-inverse;
}

%pl-tag--highlight {
  @extend %pl-tag;
  background: $tag-blue;
  color: $color-content-inverse;
}

%pl-tag--warn {
  @extend %pl-tag;
  background: $tag-orange;
  color: $color-content-inverse;
}

%pl-tag--success {
  @extend %pl-tag;
  background:  $tag-green;
  color: $color-content-inverse;
}

%pl-tag--caution {
  @extend %pl-tag;
  background: $tag-orange;
  color: $color-content-inverse;
}

%pl-tag--neutral {
  @extend %pl-tag;
  background: $color-gray-50;
  color: $color-content-inverse;
}

.pl-tag--alert,
.pl-feature-tag--alpha {
  @extend %pl-tag--alert;
}

.pl-tag--highlight,
.pl-feature-tag--new {
  @extend %pl-tag--highlight;
}

.pl-tag--warn,
.pl-feature-tag--beta {
  @extend %pl-tag--warn;
}

.pl-tag--success {
  @extend %pl-tag--success;
}

.pl-tag--caution {
  @extend %pl-tag--caution;
}

.pl-tag--neutral {
  @extend %pl-tag--neutral;
}

// Use this in tandem with classes above only for the main app feature tag that lives in the sidebar
.pl-feature-tag--app {
  margin: $feature-tag-app-spacing $feature-tag-app-spacing 0;

  @media (max-width: #{$tablet-width - 1}), (max-height: #{$tablet-width - 1}) {
    display: none;
  }
}
