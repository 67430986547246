// # Nav list
// Vertical list of navigation items

$nav-list-link-vert: .5714em;
$nav-list-link-horiz: 1.7143em;
$nav-list-icon-size: 1em;

.pl-nav-list__item {
  font-size: $font-small;
  position: relative;

  .pl-nav-list__sub-nav {
    margin-left: $layout-spacing-medium;
  }
  [class *= 'pl-feature-tag'] {
    margin-left: 6px;
    vertical-align: top;
  }
}

.pl-nav-list__link,
.pl-nav-list__link--icon {
  color: $color-content;
  display: block;
  padding: $nav-list-link-vert $nav-list-link-vert $nav-list-link-vert $nav-list-link-horiz; // (8px / 14px) (24px / 14px)
  text-decoration: none;

  .pl-nav-list__item.pl-is-selected > & {
    background-color: $color-content-xxxlight;
    box-shadow: inset 4px 0 0 0 $color-highlight;
  }
  &:focus,
  &:hover {
    box-shadow: inset 4px 0 0 0 $color-content-xlight;
    outline: none;
  }
}

.pl-nav-list__link--icon {
  padding-left: $nav-list-link-horiz * 2 + 1;
}

.pl-nav-list__icon {
  fill: $color-content-xlight;
  height: $nav-list-icon-size;
  left: $nav-list-link-horiz;
  line-height: 1;
  margin-top: -$nav-list-icon-size / 2;
  position: absolute;
  top: 50%;
  vertical-align: middle;
  width: $nav-list-icon-size;
}

.pl-nav-list__count {
  background: $color-alert;
  border-radius: 2px;
  color: $color-content-inverse;
  font-size: $font-xxsmall;
  font-weight: 600;
  line-height: $font-xxsmall * 1.4;
  margin-left: .5em;
  margin-top: .3em;
  padding: 0 2px;
  position: absolute;

  &:empty {
    display: none;
  }
}


// # Selectable list
// Vertical nested list of clickable items and collapsible sections

$selectable-spacing: 4px;
$selectable-padding-small: 8px;
$selectable-padding-large: 12px;
$selectable-padding-xlarge: 18px;

.pl-selectable-list,
%pl-selectable-list {
  display: block;
  list-style: none;
  margin: 0;
}

.pl-selectable-list__item {
  display: block;
}

.pl-selectable-list__content,
%pl-selectable-list__content {
  background-color: $bg-default;
  display: block;
  margin-bottom: $selectable-spacing;
  overflow: hidden;
  padding: $selectable-padding-small $selectable-spacing $selectable-padding-small ($selectable-padding-large * 2);
  text-overflow: ellipsis;
  white-space: nowrap;

  &.pl-is-selected {
    background-color: $color-highlight;
    color: $color-content-inverse;

    .pl-selectable-list__text {
      color: $color-content-inverse;
    }
  }
}

.pl-selectable-list__link,
%pl-selectable-list__link {
  @extend %pl-selectable-list__content;
  cursor: pointer;
}

.pl-selectable-list__text {
  color: $color-content-light;
  font-size: $font-xsmall;
}

@for $i from 1 through 5 {
  .pl-selectable-list__link--indent-#{$i} {
    @extend %pl-selectable-list__link;
    padding-left: $selectable-padding-large * ($i + 2);
  }
}

.pl-selectable-list--grid {
  @extend %pl-selectable-list;

  .pl-selectable-list__item {
    border-bottom: 1px solid $gray-light;
    &:last-child {
      margin-bottom: -1px;
    }
  }

  .pl-selectable-list__content,
  .pl-selectable-list__link {
    margin-bottom: 0;
    padding-bottom: $selectable-padding-xlarge;
    padding-top: $selectable-padding-xlarge;
  }
}
