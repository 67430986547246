%pl-status-tag {
  font-size: 16px;
  text-transform: uppercase;
}

.pl-program__status--active {
  @extend %pl-status-tag;
  color: $color-success;
}
.pl-program__status--archived {
  @extend %pl-status-tag;
  color: $color-alert;
}
.pl-program__status--draft {
  @extend %pl-status-tag;
  color: $color-highlight;
}

