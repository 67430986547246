// ## .pl-dot-list
// List Style • With Dots Between Items
// Where Dots Hide • Between Line Breaks
.pl-dot-list {
  align-items: center;
  color: $color-gray-50;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -1em;
  overflow: hidden;

  > li {
    flex: 0 0 auto;
    margin-left: -1em;
    margin-right: 1em;
    white-space: nowrap;

    &::before {
      content: '•';
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      width: 1em;
    }
  }
}
