// # Progress
// Displays a task's progress toward completion.

%pl-progress__container {
  background-color: #fff;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
  padding: 1.7143rem; // 24px / 14px
  text-align: center;
  width: 100%;
}

.pl-progress__container {
  @extend %pl-progress__container;
  box-shadow: $shadow-small;

  &--modal {
    @extend %pl-progress__container;
  }
}

.pl-progress {
  background-color: $color-highlight-light;
  box-shadow: inset 0 0 1px $color-content-light;
}

.pl-progress__bar,
%pl-progress__bar {
  background-color: $color-highlight;
  height: .5714rem; // 8px / 14px
}

.pl-progress__bar--indeterminate {
  @extend %pl-progress__bar;
  background-color: transparent;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    background-color: $color-highlight;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    will-change: left, right;
  }

  &::before {
    animation: progress-indeterminate 2.1s cubic-bezier(.65, .815, .735, .395) infinite;
  }

  &::after {
    animation: progress-indeterminate-short 2.1s 1.15s cubic-bezier(.165, .84, .44, 1) infinite;
  }
}

.pl-progress__bar--complete {
  @extend %pl-progress__bar;
  background-color: $color-success;
  width: 100%;
}

.pl-progress__label {
  @extend %content-spacing;
  @include font-medium;
}

.progress__text {
  left: -9999px;
  position: absolute;
}


// ## Animations
// Credit for these animations goes to Materialize CSS
// http://materializecss.com/preloader.html
@keyframes progress-indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes progress-indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
