$org-logo-size: 32px;
$breakpoint-mobile-max: $tablet-width;
$breakpoint-nomobile: $tablet-width + 1;

@media(min-width: $breakpoint-nomobile) {
  .pl-mobile-menu {
    display: none;
  }
}
@media(max-width: $breakpoint-mobile-max) {
  .pl-mobile-menu {
    background: white;
    bottom: 0;
    display: none;
    height: calc(100vh - $ngin-bar-height);
    left: 0;
    list-style: none;
    padding: 0;
    position: fixed;
    right: 0;
    top: $ngin-bar-height;
    width: 100%;
    z-index: 999;

    &::before {
      border-bottom-color: white;
      border-left-color: transparent;
      border-right-color: transparent;
      border-style: solid;
      border-top-color: transparent;
      border-width: 0 $nb-options-caret-size $nb-options-caret-size;
      bottom: 100%;
      content: '';
      display: block;
      height: $nb-options-caret-size;
      left: $nb-mobile-caret-offset;
      position: absolute;
      width: $nb-options-caret-size * 2;
    }
  }

  .pl-mobile-menu__link,
  .pl-mobile-menu__title {
    display: block;
    font-size: $font-medium;
    padding: 14px 10px 14px 20px;
    position: relative;
  }

  .pl-mobile-menu__link {
    border-bottom: 1px solid $gray-xlight;
    &::before {
      background: transparent;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, .025);
    }

    &.active {
      background: rgba(0, 0, 0, .05);
      border-bottom: 1px solid $gray-light;
      &::before {
        background: $color-highlight;
        width: 6px;
      }
    }
  }

  .pl-mobile-menu__title {
    border-bottom: 1px solid $gray-light;
    font-weight: bold;
    .pl-avatar--xsmall {
      vertical-align: middle;
    }
  }

  .pl-side-nav--org {
    display: none !important;
  }

}

.nb-mobile-nav-open {
  @media(max-width: $breakpoint-mobile-max) {
    .pl-mobile-menu {
      display: block;
    }
  }
}
