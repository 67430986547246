// # Ngin bar
// Displays at the top of every page. Contains navigation to user-specific
// features like notifications and log-out.

.pl-ngin-bar {
  background-color: $gray-xdark;
  color: #fff;
  line-height: $ngin-bar-height;
  padding: 0 1.7143rem; // 24px / 14px
  position: relative;
  z-index: 2; // Position above side-nav
}
.pl-ngin-bar__logo {
  transform: translateY(.1428571429rem); // 2px / 14px
  vertical-align: middle;
}

.nb-light {
  &,
  .nb-main::before {
    outline: 1px solid $gray-light; // should only ever see the bottom border
  }
}

// Font size overrides to keep the profile dropdown consistent with the left nav (based on rems)
// since the actual nginbar css is based on ems, to avoid host app size changes. If we ever change
// platform styles to use 14px rems at phone (instead of 16px), we can delete these overrides.

$se-bar-profile-menu-item-selector: '.nb-avatar + .nb-menu-dropdown .nb-menu-container:first-child .nb-list-item:first-child';

@media (max-width: #{$media-small - 1}) {
  .nb-menu-dropdown {
    font-size: 16 / 14 * 100%; // size up all dropdown content
  }
  #{$se-bar-profile-menu-item-selector} {
    font-size: 14 / 16 * 100%; // reset this back to normal size
  }
}
.pl-has-side-nav {
  @media (max-width: #{$media-small + $side-nav-width - 1}) {
    .nb-menu-dropdown {
      font-size: 16 / 14 * 100%; // size up all dropdown content
    }
    #{$se-bar-profile-menu-item-selector} {
      font-size: 14 / 16 * 100%; // reset this back to normal size
    }
  }
}
