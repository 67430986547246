$recommended-action-spacing: $layout-spacing-large;

// Calculated
$recommended-action-spacing-vert: $recommended-action-spacing * .5;
$recommended-action-spacing-outer: $recommended-action-spacing * .75;
$recommended-action-spacing-inner: $recommended-action-spacing * .25;

// styles should match se-card from the component library
.pl-recommended-actions {
  background: $bg-default;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .12), 0 2px 2px 0 rgba(0, 0, 0, .24);
  box-sizing: border-box;
  display: block;
  width: 100%;
}

%pl-recommended-action {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: $recommended-action-spacing-vert $recommended-action-spacing-outer;

  & + & {
    border-top: 1px solid $color-gray-10;
  }
}

.pl-recommended-action-skeleton {
  @extend %pl-recommended-action;
  align-items: center;
  font-size: $font-small;
  justify-content: space-between;
  min-height: 72px;

  &::before {
    @include pl-skeleton-text($color-gray-50);
    flex: 0 0 auto;
    width: 60%;
  }

  &::after {
    @include pl-skeleton-button($color-gray-50);
    flex: 0 0 auto;
    width: 6em;
  }
}

.pl-recommended-action {
  @extend %pl-recommended-action;

  &__indicator {
    flex: 0 0 auto;
    padding: $recommended-action-spacing-inner 0;
    transform: translateY(-1px);
  }

  &__content {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (min-width: #{$desktop-width}) {
      flex-wrap: nowrap;
    }
  }

  &__text {
    flex: 1 100 50%;
    padding: $recommended-action-spacing-inner;
  }

  &__title {
    color: $color-gray-85;
    display: inline;
    font-size: $font-small;
    font-weight: $font-semibold;
  }

  &__subtitle {
    color: $color-gray-50;
    display: inline;
    font-size: $font-small;
    font-weight: $font-regular;
  }

  &__buttons {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width: #{$desktop-width - 1}) {
      padding-top: $recommended-action-spacing-inner;
    }
  }

  &__button {
    margin: $recommended-action-spacing-inner;

    + & {
      margin-left: 0;
    }
  }
}
