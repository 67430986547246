.membership-detail-container {
  margin-bottom: -$layout-spacing-medium;
  margin-top: -$layout-spacing-medium;
  text-align: left;
}

.membership-detail-info {
  &__key {
    font-size: $font-medium;
    font-weight: $font-semibold;
  }
  &__value {
    color: $color-gray-50;
    font-weight: $font-regular;
  }
  &__description {
    margin-top: $layout-spacing-normal;
  }
  &-description__text {
    @extend %membership-description-block;
    margin-top: $layout-spacing-small;
  }
}

%membership-detail-past-memberships {
  color: $color-gray-50;
  font-size: $font-small;
  font-weight: $font-regular;
}

.membership-detail-past-memberships {
  &__name {
    color: $color-gray-85;
    font-size: $font-medium;
    font-weight: $font-semibold;
  }

  &__dates {
    @extend %membership-detail-past-memberships;
  }

  &__sale-label {
    @extend %membership-detail-past-memberships;
    font-weight: $font-semibold;
  }

  &__sale-value {
    @extend %membership-detail-past-memberships;
  }
}

%membership-description-block {
  hyphens: auto;
  white-space: pre-line;
  word-break: break-word;
}

.membership-description-block {
  @extend %membership-description-block;
}
