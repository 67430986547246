$info-message-margin: 3px;
$tooltip-content-size: 12px;

.pl-info-messages-wrapper {
  display: flex;
  margin-top: 5px;

  .pl-color--highlight.pl-help-text {
    cursor: pointer;
  }

  .pl-info-message {
    margin-left: $info-message-margin;
  }
}

.pl-tooltip {
  .pl-tooltip-content {
    font-size: $tooltip-content-size;
  }
}
