// Play around with this code here: https://jsfiddle.net/ytjrbzkg/5/
$switch-size: 28px;
$switch-border-width: 1px;
$switch-padding: 3px;

.pl-switch__input {
  left: -9999px;
  position: absolute;
}
%pl-switch__pseudo {
  background-color: $gray;
  border-radius: $switch-size - $switch-padding * 2;
  bottom: $switch-padding;
  box-shadow: 0 0 0 $switch-padding $bg-default;
  content: '';
  position: absolute;
  right: $switch-size + $switch-padding;
  top: $switch-padding;
  transition: .15s;

  .pl-switch__input:checked + & {
    right: $switch-padding;
  }
}

.pl-switch,
%pl-switch {
  background-color: $bg-default;
  border: $switch-border-width solid $gray;
  border-radius: $switch-size / 2;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: $switch-size;
  margin: calc(.5em - #{$switch-size / 2 + $switch-border-width * 2}) .5em; // negative top/bottom make it 1em line-height
  overflow: hidden;
  position: relative;
  user-select: none;
  width: $switch-size * 2;

  &--full-height {
    margin: 1px .5em;
    vertical-align: calc(.3em - 13px); // align middle with surrounding text
  }

  .pl-switch__input:first-child + & {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  // LATER: support showing text inside, just hide it for now
  &:not(:empty) {
    padding-left: ($switch-size - $switch-border-width) * 2;
  }

  &::before {
    @extend %pl-switch__pseudo;
    left: $switch-padding;
    opacity: .5;
  }
  &::after {
    @extend %pl-switch__pseudo;
    width: $switch-size - ($switch-border-width + $switch-padding) * 2;
  }

  .pl-switch__input:disabled + & {
    border-color: $color-content-xlight;
    cursor: auto;

    &::before,
    &::after {
      background-color: $color-content-xlight;
    }
  }
}

// ## Color classes
@each $label, $color in (highlight: $color-highlight, alert: $color-alert, warn: $color-warn, success: $color-success) {
  .pl-switch--#{$label} {
    @extend %pl-switch;

    &::before,
    &::after {
      background-color: $color;
    }
  }
}
// ## Switch with white background
.pl-switch--empty {
  @extend %pl-switch;

  &::before {
    background-color: $bg-default !important;
  }
}
