$us-alert-panel-icon-size: 32px;

.us-alert-panel {
  color: $gray-dark;

  &__inner {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding: $layout-spacing-large;
  }

  &__icon {
    flex: 0 0 auto;
    height: $us-alert-panel-icon-size;
    padding-right: $layout-spacing-small;
    width: $us-alert-panel-icon-size + $layout-spacing-small;
  }

  &__content {
    flex: 1 1 100%;
  }
}

.us-alert-panel--alert {
  background: $color-alert-light;

  .us-alert-panel__icon {
    fill: $color-alert;
  }
}

.pl-wizard__panel {
  .us-alert-panel,
  [class*='us-alert-panel--'] {
    margin: $layout-spacing-large (-$layout-spacing-medium);

    @media (min-width: $media-tablet) {
      margin: $layout-spacing-large ($layout-spacing-large * -3);
    }
  }

  .us-alert-panel__inner {
    padding-left: $layout-spacing-medium;
    padding-right: $layout-spacing-medium;

    @media (min-width: $media-tablet) {
      padding-left: $layout-spacing-large * 3;
      padding-right: $layout-spacing-large * 3;
    }
  }
}
