$onboarding-header-side-padding: 20px;
$onboarding-icon-width: 40px;
$onboarding-spacing-xlarge: $layout-spacing-large * 1.5;
$onboarding-task-number-radius: 10px;
$tablet-width: 770px;

onboarding-task-list {
  .onboarding-header__items {
    align-items: center;
    padding: $layout-spacing-normal $onboarding-header-side-padding $layout-spacing-xsmall;
  }

  .onboarding-header__left {
    align-items: center;
    display: flex;
    font-weight: bold;
  }

  .onboarding-header__tools-icon {
    background-image: url('#{$shared-image-path}/onboarding/sportsengine-tools-icon.svg');
    display: inline-block;
    height: $onboarding-spacing-xlarge;
    margin-right: $layout-spacing-small;
    width: $onboarding-icon-width;
  }

  .onboarding-task-check {
    margin-right: $layout-spacing-xsmall;
  }

  .onboarding-task-container {
    border: solid 1px $color-gray-15;
    border-radius: $layout-spacing-xsmall;
    &--active,
    &:hover,
    &:focus-within {
      border-color: #008cc3;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    }
  }

  .onboarding-task-title {
    font-size: 14px;
  }

  .onboarding-task-content {
    padding: 0 $onboarding-spacing-xlarge $layout-spacing-large;
  }

  .onboarding-task-header {
    @extend %button-unstyled-default;
    padding: 18px 12px;
    text-align: left;
    width: 100%;
  }

  .onboarding-task-number {
    align-items: center;
    border: solid 1px $color-gray-50;
    border-radius: $onboarding-task-number-radius;
    color: $color-gray-50;
    display: inline-flex;
    font-size: $font-xsmall;
    font-weight: bold;
    height: $onboarding-task-number-radius * 2;
    justify-content: center;
    margin-right: $layout-spacing-xsmall;
    width: $onboarding-task-number-radius * 2;
  }

  &.onboarding-task-list--closed {
    .se-card {
      padding-bottom: 0;
    }

    se-progress-bar {
      border-bottom-width: 0;
    }

    div[ng-transclude='se-cp-card-header'] {
      margin-bottom: 0;
    }
  }

  onboarding-task:not(:first-of-type) {
    display: block;

    &:not(:first-of-type) {
      margin-top: $layout-spacing-small;
    }
  }
}

// Begin styles for OnboardingStep[1,2,3]Component  (MOBILE FIRST)
// TODO: refactor reusable responsive layout styles for content (text/graphics/videos) across all steps
// TODO: replace hardcoded values with Sass variables where able
.onboarding-step__container {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
}

.onboarding-step1__content--video-button {
  @extend %button-unstyled-default;
  display: inline-block;
  height: fit-content;
  max-width: 400px;
  position: relative;
}

.onboarding-step1__content--text,
.onboarding-step2__content--text,
.onboarding-step3__content--text,
.onboarding-step4__content--text,
.onboarding-step5__content--text {
  color: $color-gray-50;
  padding: 12px 0;
}

.onboarding-step__content--item-list {
  color: $color-gray-85;
}

.onboarding-step__content--image {
  display: inline-block;
  flex-shrink: 0;
  height: fit-content;
  width: 300px;
}

// (TABLET and DESKTOP)
@media screen and (min-width: $tablet-width) {
  .onboarding-step__container {
    align-items: stretch;
    flex-direction: row;
  }

  .onboarding-step1__content--video-button {
    z-index: 5;

    &::before {
      border: 1px dashed $color-nbc-blue;
      border-radius: 4px;
      content: '';
      height: 98%;
      left: -4%;
      position: absolute;
      top: -5%;
      width: 100%;
      z-index: -5;
    }
  }

  .onboarding-step1__content--text {
    padding: 0 40px 0 0;
  }
  .onboarding-step2__content--text,
  .onboarding-step3__content--text,
  .onboarding-step4__content--text,
  .onboarding-step5__content--text {
    margin-right: 36px;
    padding: 0;
    width: 414px;
  }

  .onboarding-step1__arrow-icon {
    background-image: url('#{$shared-image-path}/onboarding/dotted-arrow.svg');
    display: inline-block;
    height: 100px;
    margin-left: 230px;
    transform: scaleX(-1);
    width: 167px;
  }

  .onboarding-step__content--image {
    width: 400px;
  }
}
