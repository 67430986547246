%pl-video-overlay-frame {
  background-color: black;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, .5);
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

.pl-video-overlay {

  &__video {
    @extend %pl-video-overlay-frame;

    iframe,
    &-embed {
      height: 100% !important; // override inline sizing
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important; // override inline sizing
    }
  }

  &__poster {
    @extend %pl-video-overlay-frame;
    background-position: center center;
    background-size: cover;
    border: 0;
  }

  &__overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, .9);
    bottom: 0;
    display: flex;
    height: 100%;
    left: 0;
    opacity: 0;
    outline: 200px solid rgba(0, 0, 0, .9);
    overflow: hidden;
    position: fixed;
    top: 100vh;
    transition: top 300ms ease-in-out, opacity 300ms ease-in-out;
    width: 100%;
    z-index: 2147483647; // max int to be above elev.io

    @media (min-width: $tablet-width) {
      padding: 0 5%;
    }

    &.is-visible {
      opacity: 1;
      top: 0;
    }
  }

  &__close {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;

    se-icon-button > * svg {
      color: #fff;
    }
  }
}
